import { Stack, Typography } from '@mui/material';
//
import { CalendarAsideResponseItemPoints } from 'src/store/calendar-aside/types';

type Props = {
  index: number;
  point: CalendarAsideResponseItemPoints;
};

export default function CalendarAsidePointsListItem(props: Props) {
  const { point, index } = props;

  return (
    <Stack sx={{ p: 0.75, borderRadius: 1, bgcolor: 'background.default' }}>
      <Typography variant="subtitle2">
        <b>{index + 1}</b> - {point.name}
      </Typography>
    </Stack>
  );
}
