import React from 'react';
//
import LayoutDashboard from 'src/layouts/dashboard';
//
const TaskCreatePage = React.lazy(() => import('src/pages/tasks/create'));
const TaskDefinifionPage = React.lazy(() => import('src/pages/tasks/definition'));

export const tasksRoutes = [
  {
    path: 'tasks',
    element: <LayoutDashboard />,
    children: [
      { element: <TaskCreatePage />, index: true },
      { path: 'definition', element: <TaskDefinifionPage /> },
    ],
  },
];
