import { PoolsType } from '../pools/types';
import { TaskDefinitionFormStoreTaskStatusEnum } from '../task-definition-form/types';
import { TeamsType } from '../teams/types';
import { UsersType } from '../users/types';

export type CalendarLayoutStore = {
  asideVisible: boolean;
  bottomVisible: boolean;
  //
  date: string;
  dayView: CalendarLayoutDayView;
  // Filters
  filtersVisible: boolean;
  filters: {
    selectedsTeams: TeamsType[];
    selectedsPools: PoolsType[];
    selectedsPersons: UsersType[];
    //
    statuses: CalendarLayoutFilterStatusesItem[];
  };
};

export enum CalendarLayoutDayView {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export type CalendarLayoutFilterStatusesItem = {
  title: string;
  color: string;
  checked: boolean;
  key: TaskDefinitionFormStoreTaskStatusEnum | string;
  keys: TaskDefinitionFormStoreTaskStatusEnum[];
};
