import * as React from 'react';
import { useDrag } from 'react-dnd';
import { Clock } from 'iconsax-react';
import { Box, Grid, PopoverPosition, Stack, Typography } from '@mui/material';
//
import { DraggablesTypes } from 'src/utils/draggables';
import ProgramItemActions from '../../customize/components/program-item-actions';
import ProgramItemDetail from '../../customize/components/program-item-detail';
import { CalendarAsideResponseItem } from 'src/store/calendar-aside/types';

interface Props {
  index: number;
}

type State = {
  anchorEl: HTMLElement | null;
  anchorPosition: PopoverPosition | null;
};

export default function CalendarBottomUnasignedTasksItem(props: Props) {
  const { index } = props;
  const [state, setState] = React.useState<State>({
    anchorEl: null,
    anchorPosition: null,
  });

  const id = index.toString();

  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: DraggablesTypes.CALENDAR_ITEM,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.8 : 1,
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    item: { id },
  }));

  const color = index % 2 === 0 ? 'error' : 'primary';

  const handleRightClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setState({
      anchorEl: event.currentTarget,
      anchorPosition: { left: event.clientX, top: event.clientY },
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setState({ anchorPosition: null, anchorEl: event.currentTarget });
  };

  const onClose = () => {
    setState({ anchorEl: null, anchorPosition: null });
  };

  return (
    <Grid item xs={12} md={6}>
      <Stack
        ref={drag}
        id={id}
        spacing={0.75}
        direction="row"
        alignItems="center"
        onClick={handleClick}
        onContextMenu={handleRightClick}
        onDragStart={(e) => e.dataTransfer.setData('text/plain', id)}
        sx={{
          opacity,
          zIndex: 1,
          height: 32,
          borderRadius: 1,
          cursor: 'pointer',
          overflow: 'hidden',
          color: `${color}.main`,
          bgcolor: `${color}.lighter`,
          position: state.anchorEl ? 'relative' : 'initial',
        }}
      >
        <Box width={10} height={1} bgcolor={`${color}.main`} />
        <Clock variant="Bold" size={16} />
        <Typography variant="subtitle2" flex={1} pr={0.75}>
          Anket - 20/24 Haziran 09:00 - 18:30
        </Typography>
      </Stack>
      {state.anchorEl && (
        <>
          {state.anchorPosition ? (
            <ProgramItemActions
              onClose={onClose}
              anchorEl={state.anchorEl}
              anchorPosition={state.anchorPosition}
              // @ts-ignore
              calendarRightItem={{} as CalendarAsideResponseItem}
            />
          ) : (
            <ProgramItemDetail
              onClose={onClose}
              anchorEl={state.anchorEl}
              // @ts-ignore
              calendarRightItem={{} as CalendarAsideResponseItem}
            />
          )}
        </>
      )}
    </Grid>
  );
}
