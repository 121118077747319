import React from 'react';
import { Stack, StackProps, Tooltip } from '@mui/material';

interface Props extends StackProps {
  title: string;
  onClick?(): void;
  disabled?: boolean;
}

export default function ButtonIcon(props: React.PropsWithChildren<Props>) {
  const { title, onClick, children, sx, ...other } = props;

  return (
    <Tooltip title={title}>
      <Stack
        onClick={onClick}
        disabled={props.disabled}
        sx={{
          width: 36,
          height: 36,
          borderRadius: 1,
          cursor: 'pointer',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          bgcolor: 'background.default',
          ...sx,
        }}
        {...other}
      >
        {children}
      </Stack>
    </Tooltip>
  );
}
