import { create } from 'zustand';
import { Theme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
//
import axios, { endpoints } from 'src/utils/axios';
import useMapAsideTeamsStore from '../map-aside-teams';
import { TaskItemAssignmentData } from '../tasks/types';
import useMapPointsMarkerStore from '../map-points-marker';
import useMapAsidePersonsStore from '../map-aside-persons';
import { MapPointSearchResponseListItem } from '../map-points/types';
import { MapActionsStore, PointsAllocationRequestPayload } from './types';
import { getMapsBottomAllTasksToPlanned } from '../maps-bottom-tasks-to-planned';
import useMapsBottomTasksStore, { getMapsAllBottomTasks } from '../maps-bottom-tasks';

const initialState: MapActionsStore = {
  map: null,
  fullScreen: false,
  selectedsPoints: [],
  polygonActive: false,
  drawingManager: null,
  //
  requestLoading: false,
};

const useMapActionsStore = create<MapActionsStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set((s) => ({ ...s, polygonActive: false, selectedsPoints: [] })),
}));

export default useMapActionsStore;

const { getState, setState } = useMapActionsStore;

export const mapActionsOnClickPolygon = (theme: Theme) => {
  const state = getState();

  setState({ polygonActive: !state.polygonActive });

  if (state.polygonActive) {
    if (state.drawingManager) {
      state.drawingManager.setDrawingMode(null);
    }
    return;
  }

  const newDrawingManager = new google.maps.drawing.DrawingManager({
    drawingControl: false,
    map: state.map,
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    polygonOptions: {
      editable: false,
      draggable: false,
      strokeWeight: 2.5,
      fillOpacity: 0.75,
      fillColor: theme.palette.text.disabled,
      strokeColor: theme.palette.text.primary,
    },
  });

  newDrawingManager.addListener('polygoncomplete', (polygon: google.maps.Polygon) => {
    const points = useMapPointsMarkerStore.getState().response.data;

    // Polygonun içinde olup olmadığını kontrol et
    const pointsInsidePolygon = points.filter((location) => {
      const point = new google.maps.LatLng(location.COORDINATE_Y, location.COORDINATE_X);
      return google.maps.geometry.poly.containsLocation(point, polygon);
    });

    useMapActionsStore.setState({ selectedsPoints: pointsInsidePolygon });

    // clear polygon
    newDrawingManager.setDrawingMode(null);
    setTimeout(() => polygon.setMap(null), 1000);
    useMapActionsStore.setState({ polygonActive: false });
  });

  useMapActionsStore.setState({ drawingManager: newDrawingManager });
};

export const mapActionsCenteredMap = (points: MapPointSearchResponseListItem[]) => {
  const state = getState();
  const bounds = new window.google.maps.LatLngBounds();

  points.forEach((point) => {
    bounds.extend(new window.google.maps.LatLng(point.COORDINATE_Y, point.COORDINATE_X));
  });

  state.map?.fitBounds(bounds);
};

export const handlePointsAllocation = async () => {
  setState({ requestLoading: true });
  const state = getState();
  const mapsBottomTasksStore = useMapsBottomTasksStore.getState();
  const mapAsidePersonsStore = useMapAsidePersonsStore.getState();
  const mapAsideTeamsStore = useMapAsideTeamsStore.getState();

  const assignments: TaskItemAssignmentData[] = [];
  const assignmentsLength =
    mapAsidePersonsStore.selectedItems.length + mapAsideTeamsStore.selectedItems.length;

  for (let i = 0; i < assignmentsLength; i++) {
    assignments.push({
      employeeId: mapAsidePersonsStore.selectedItems[i]?.id || '',
      employeeName: mapAsidePersonsStore.selectedItems[i]?.userName || '',
      //
      teamId: mapAsideTeamsStore.selectedItems[i]?.id || '',
      teamName: mapAsideTeamsStore.selectedItems[i]?.name || '',
      poolId: '',
      poolName: '',
      roleId: '',
      roleName: '',
    });
  }

  const selectedTasks = mapsBottomTasksStore.response.list
    .filter((task) => {
      return (
        Object.entries(mapsBottomTasksStore.rowSelection)
          // Numbere çevirmemizin sebebi, tabloda getRowId fonksiyonu ile id'yi stringe çeviriyoruz.
          // Çünkü tablo bizden string bir id bekliyor.
          .map(([id]) => Number(id))
          .includes(task.id)
      );
    })
    .map((item) => ({ id: item.id, name: item.name }));

  const payload: PointsAllocationRequestPayload = {
    assignments,
    tasks: selectedTasks,
    points: state.selectedsPoints.map((point) => ({
      id: point.id,
      name: point.SIGN_NAME,
      COORDINATE_X: point.COORDINATE_X,
      COORDINATE_Y: point.COORDINATE_Y,
    })),
  };

  try {
    await axios.post(endpoints.task.pointsAllocation, payload);
    await getMapsAllBottomTasks();
    await getMapsBottomAllTasksToPlanned();
    // Clear selected items
    setState({ selectedsPoints: [] });
    useMapAsideTeamsStore.setState({ selectedItems: [] });
    useMapAsidePersonsStore.setState({ selectedItems: [] });
    useMapsBottomTasksStore.getState().onRowSelectionChange({});
    enqueueSnackbar('Seçilenler başarıyla atanmıştır.', { variant: 'success' });
  } catch (error) {
    enqueueSnackbar('Bir hata oluştu', { variant: 'error' });
  } finally {
    setState({ requestLoading: false });
  }
};
