import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
//
import TaskSelectedBox from '../task-selected-box';

interface Props {}

export default function MapAsideSubWrapperEmptyDetail(props: Props) {
  return (
    <Stack
      p={2}
      flex={1}
      spacing={1.5}
      sx={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        component="img"
        src="/img/empty.svg"
        sx={{ width: 220, height: 200, objectFit: 'contain' }}
      />
      <Typography variant="h5">Henüz bir task atanmamış gibi duruyor.</Typography>
      <Typography variant="caption" color="text.secondary">
        Bir sorun olduğunu mu düşünüyorsunuz? Sorunu bize bildirmek için aşağıdaki butona
        tıklayabilirsiniz.
      </Typography>
      <TaskSelectedBox />
    </Stack>
  );
}
