import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, Stack, Typography } from '@mui/material';
//
import Item from './item';
import LoadingItems from 'src/components/loading-items';
import MapAsideWrapperEmptyDetail from '../../../empty-detail';
import MapAsideWrapperTaskAssignmentPersonsItemEmpty from './empty';
import useMapAsidePersonsStore, { getMapsAsidePersonsSearch } from 'src/store/map-aside-persons';

export default function MapAsideWrapperTaskAssignmentPersons() {
  const mapAsidePersonsStore = useMapAsidePersonsStore(useShallow((s) => s));

  if (mapAsidePersonsStore.loading) {
    return (
      <LoadingItems height={1} count={20} spacing={1.5}>
        <MapAsideWrapperTaskAssignmentPersonsItemEmpty />
      </LoadingItems>
    );
  }

  if (mapAsidePersonsStore.response.count === 0) {
    return <MapAsideWrapperEmptyDetail />;
  }

  const nextFetch = () => {
    if (mapAsidePersonsStore.paginationLoading) {
      return;
    }

    useMapAsidePersonsStore.setState({ paginationLoading: true });
    getMapsAsidePersonsSearch(mapAsidePersonsStore.search, true);
  };

  return (
    <InfiniteScroll
      next={nextFetch}
      dataLength={mapAsidePersonsStore.response.items.length}
      hasMore={mapAsidePersonsStore.response.totalPages > mapAsidePersonsStore.response.totalItems}
      loader={
        mapAsidePersonsStore.loading ? (
          <Stack height={20} overflow="hidden" alignItems="center">
            <CircularProgress size={20} />
          </Stack>
        ) : null
      }
      scrollableTarget="persons-teams-pools-scrollable-div"
      endMessage={
        <Typography variant="subtitle2" textAlign="center" color="text.disabled">
          Listenin sonuna geldiniz...
        </Typography>
      }
    >
      {mapAsidePersonsStore.response.items.map((person) => {
        const checked = mapAsidePersonsStore.selectedItems.some((item) => item.id === person.id);

        return <Item key={person.id} item={person} checked={checked} />;
      })}
    </InfiniteScroll>
  );
}
