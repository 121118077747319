import React from 'react';
import axios from 'src/utils/axios';

interface Props {
  url: string;
  defaultValue?: any;
  initialStop?: boolean;
  method?: 'get' | 'post';
  body?: Record<string, any>;
  query?: Record<string, string>;
}

export interface FetchResults<Results> {
  data: Results;
  error: boolean;
  loading: boolean;
  getData: () => Promise<void>;
}

export default function useFetch<Results>(props: Props): FetchResults<Results> {
  const { url, method = 'get', query, body, defaultValue, initialStop } = props;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState<Results>(defaultValue);

  const queryStr = React.useMemo(() => {
    if (!query) {
      return '';
    }

    const queries = Object.entries(query)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return queries;
  }, [query]);

  const getData = React.useCallback(async () => {
    setError(false);
    setLoading(true);

    const fullUrl = queryStr ? `${url}?${queryStr}` : url;

    try {
      const response = await axios[method](fullUrl, body);
      setData(response.data);
    } catch (error) {
      setError(true);
      setData(defaultValue);
    } finally {
      setLoading(false);
    }
  }, [body, defaultValue, method, queryStr, url]);

  React.useEffect(() => {
    if (!initialStop) {
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStop]);

  return { loading, error, data, getData };
}
