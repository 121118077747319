import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
//
import { mapRoutes } from './map';
import { mainRoutes } from './main';
import { tasksRoutes } from './tasks';
import { settingsRoutes } from './settings';
import { calendaarRoutes } from './calendar';
import { dashboardRoutes } from './dashboard';
import { PATH_AFTER_LOGIN } from 'src/config-global';

export default function CustomRouter() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Dashboard routes
    ...dashboardRoutes,

    // Tasks routes
    ...tasksRoutes,

    // Settings routes
    ...settingsRoutes,

    // Main routes
    ...mainRoutes,

    // Map routes
    ...mapRoutes,

    // Calendar routes
    ...calendaarRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
