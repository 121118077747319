import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
//
import { MapsLayoutBottomTabsEnum, MapsLayoutEnum, MapsLayoutStore } from './types';

const initialState: MapsLayoutStore = {
  asideSubVisible: null,
  activeType: MapsLayoutEnum.L,
  bottomActiveTab: MapsLayoutBottomTabsEnum.TASKS,
};

const useMapsLayoutStore = create(
  persist<MapsLayoutStore & { reset(): void }>(
    (set) => ({
      ...initialState,
      reset: () => set((s) => ({ ...s, asideSubVisible: null })),
    }),
    {
      name: 'maps-layout',
      partialize: (s) =>
        ({
          activeType: s.activeType,
          bottomActiveTab: s.bottomActiveTab,
        } as MapsLayoutStore & { reset(): void }),

      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useMapsLayoutStore;
