import React from 'react';
import { useLoadScript } from '@react-google-maps/api';
//
import { MapProviderContextProps } from './types';
import { GOOGLE_MAP_API_KEY } from 'src/config-global';
import useMapActionsStore from 'src/store/map-actions';

const MapProviderContext = React.createContext<MapProviderContextProps>({
  onUnmount: () => {},
  onLoad: (map: google.maps.Map) => {},
});

export default function MapProvider(props: React.PropsWithChildren) {
  const { isLoaded } = useLoadScript({
    libraries: ['drawing'],
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  const onLoad = React.useCallback((map: google.maps.Map) => {
    useMapActionsStore.setState({ map });
  }, []);

  const onUnmount = React.useCallback(function callback() {}, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <MapProviderContext.Provider value={{ onLoad, onUnmount }}>
      {props.children}
    </MapProviderContext.Provider>
  );
}

export const useMapProvider = () => React.useContext(MapProviderContext);
