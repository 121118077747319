import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import axios, { endpoints } from 'src/utils/axios';
import useMapAsideTeamsStore from '../map-aside-teams';
import useMapAsidePoolsStore from '../map-aside-pools';
import { MapAsidePersonsListItem, MapAsidePersonsResponse, MapAsidePersonsStore } from './types';

const initialState: MapAsidePersonsStore = {
  search: '',
  loading: true,
  selectedItems: [],
  searchLoading: false,
  paginationLoading: false,
  //
  response: {
    count: 0,
    items: [],
    totalPages: 0,
    totalItems: 0,
  },
};

const useMapAsidePersonsStore = create<MapAsidePersonsStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useMapAsidePersonsStore;

const { setState, getState } = useMapAsidePersonsStore;

export const getMapsAsidePersonsSearch = async (search: string, hasPagination?: boolean) => {
  try {
    const res = await axios.get<{ data: MapAsidePersonsResponse }>(
      endpoints.users.list(20, 0, search)
    );
    setState({ response: res.data.data });
  } catch (error) {
    enqueueSnackbar('Kişiler getirilirken bir hata oluştu.', { variant: 'error' });
  } finally {
    setState({ loading: false, searchLoading: false, paginationLoading: false });
  }
};

export const onChangeMapAsidePersonsChecked = (item: MapAsidePersonsListItem, checked: boolean) => {
  const state = getState();

  // Seçilen ekipleri temizle
  if (useMapAsideTeamsStore.getState().selectedItems.length > 0) {
    useMapAsideTeamsStore.setState({ selectedItems: [] });
    enqueueSnackbar('Kişiler de işlem yaptığınız için seçilen Ekipler temizlendi.', {
      variant: 'default',
    });
  }

  // Seçilen havuzları temizle
  if (useMapAsidePoolsStore.getState().selectedItems.length > 0) {
    useMapAsidePoolsStore.setState({ selectedItems: [] });
    enqueueSnackbar('Kişiler de işlem yaptığınız için seçilen Havuzlar temizlendi.', {
      variant: 'default',
    });
  }

  const selectedItems = checked
    ? [...state.selectedItems, item]
    : state.selectedItems.filter((i) => i.id !== item.id);

  setState({ selectedItems });
};
