import { create } from 'zustand';
//
import axios, { endpoints } from 'src/utils/axios';
import { UsersResponseType, UsersStoreType } from './types';

const initialState: UsersStoreType = {
  count: 0,
  items: [],
  error: false,
  loading: true,
};

const useUsersStore = create<UsersStoreType>(() => ({
  ...initialState,
}));

export default useUsersStore;

const { setState } = useUsersStore;

// REQUESTS
export const getAllUsers = async () => {
  setState(initialState);
  try {
    const response = await axios.get<{ data: UsersResponseType }>(endpoints.users.list(100, 0));
    setState(response.data.data);
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};
