import { enqueueSnackbar } from 'notistack';
import numeral from 'numeral';

export function shortenFilename(filename: string, maxLength: number) {
  // Dosya adının uzunluğunu alın
  const length = filename.length;

  // Dosya adı 5 karakterden uzunsa
  if (length > maxLength) {
    // Dosya adının ilk 5 karakterini ve son 5 karakterini alın
    const shortened = `${filename.substring(0, 5)}...` + filename.substring(length - 5);
    return shortened;
  }

  // Dosya adı 5 karakterden kısa veya eşitse, değiştirme yapmadan döndürün
  return filename;
}

export function fileSizeData(number: number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  function result(format: string, key = '.00') {
    const isInteger = format.includes(key);

    return isInteger ? format.replace(key, '') : format;
  }

  return result(format, '.0');
}

export const urlRegex =
  /((http|https):\/\/)?(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?/;

export const clipboardCopy = (text: string, infoText: string) => {
  navigator.clipboard.writeText(text).then(() => {
    enqueueSnackbar(infoText || 'Kopyalandı', { variant: 'success' });
  });
};
