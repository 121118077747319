import { paths } from 'src/routes/paths';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

export const BASE_API = process.env.REACT_APP_BASE_API as string;
export const HOST_API = process.env.REACT_APP_HOST_API as string;
export const HOST_AUTH = process.env.REACT_APP_HOST_AUTH as string;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY as string;

export const layoutMapConfig = {
  navbarSize: 56,
  headerSize: 56,
};
