import * as React from 'react';
import { Grid } from '@mui/material';
//
import CalendarBottomUnasignedTasksItem from './item';

interface Props {}

export default function CalendarBottomUnasignedTasks(props: Props) {
  return (
    <Grid container spacing={1.5}>
      {[...new Array(30)].map((item, index) => {
        return <CalendarBottomUnasignedTasksItem key={index} index={index} />;
      })}
    </Grid>
  );
}
