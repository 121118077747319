import * as React from 'react';
import { Button, Stack } from '@mui/material';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';

interface Props {
  visible: boolean;
  toggleVisible(): void;
}

export default function CalendarBottomHeader(props: Props) {
  const { visible, toggleVisible } = props;
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Button
        color="inherit"
        onClick={toggleVisible}
        startIcon={
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ width: 24, height: 24, bgcolor: 'background.neutral', borderRadius: 1 }}
          >
            {visible ? <ArrowDown2 size={14} /> : <ArrowUp2 size={14} />}
          </Stack>
        }
      >
        Unasigned Taskler
      </Button>
    </Stack>
  );
}
