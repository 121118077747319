import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
//
import LayoutNavbar from './navbar';
import Loading from 'src/components/loading';
import useResponsive from 'src/theme/useResponsive';
import { getBaseWorkspace } from 'src/store/base-workspaces';

export default function LayoutDashboard() {
  const hasMobile = Boolean(useResponsive('down', 'lg'));

  React.useEffect(() => {
    getBaseWorkspace();
  }, []);

  return (
    <Stack
      minHeight="100dvh"
      height={hasMobile ? 'auto' : '100dvh'}
      direction={{ xs: 'column', lg: 'row' }}
      bgcolor={hasMobile ? 'background.neutral' : 'background.default'}
    >
      <LayoutNavbar hasMobile={hasMobile} />
      <Box sx={{ p: hasMobile ? 0 : 2, pl: 0, flex: 1, overflowX: 'hidden' }}>
        <Stack
          component="main"
          sx={{
            minHeight: 1,
            position: 'relative',
            borderRadius: hasMobile ? 0 : 2,
            bgcolor: 'background.neutral',
          }}
        >
          <React.Suspense fallback={<Loading />}>
            <Outlet />
          </React.Suspense>
        </Stack>
      </Box>
    </Stack>
  );
}
