import React from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { Calendar, Monitor } from 'iconsax-react';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
//
import { paths } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import { layoutMapConfig } from 'src/config-global';
import useMapsLayoutStore from 'src/store/maps-layout';
import { MapsLayoutEnum } from 'src/store/maps-layout/types';

type LayoutBoxesProps = {
  type: MapsLayoutEnum;
};
const LayoutBoxes = (props: LayoutBoxesProps) => {
  const { type } = props;

  const renderContent = () => {
    if (type === 'U') {
      return <Box sx={{ width: 8, height: 14, bgcolor: 'primary.light', alignSelf: 'center' }} />;
    }

    return (
      <Box
        sx={{
          width: 14,
          height: 14,
          alignSelf: 'flex-end',
          bgcolor: 'primary.light',
        }}
      />
    );
  };

  return (
    <Stack
      sx={{
        width: 20,
        height: 20,
        borderRadius: 1,
        overflow: 'hidden',
        bgcolor: 'primary.main',
      }}
    >
      {renderContent()}
    </Stack>
  );
};

interface Props {}

export default function LayoutMapNavbar(props: Props) {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <Stack
      px={2.25}
      spacing={1}
      direction="row"
      component="header"
      alignItems="center"
      height={layoutMapConfig.navbarSize}
      sx={{ borderBottomStyle: 'solid', borderBottomWidth: 1, borderBottomColor: 'divider' }}
    >
      <Button startIcon={<Calendar variant="Bulk" />} onClick={() => navigate(paths.calendar)}>
        Takvim
      </Button>
      <Menu
        transition
        theming={palette.mode}
        menuStyle={{ padding: 0 }}
        menuButton={<Button startIcon={<Monitor variant="Bulk" />}>Yerleşimi Değiştir</Button>}
      >
        {Object.entries(MapsLayoutEnum).map(([key, value]) => (
          <MenuItem
            key={key}
            style={{ padding: '6px 12px' }}
            onClick={() => {
              useMapsLayoutStore.setState({ activeType: value });
            }}
          >
            <Typography flex={1} variant="subtitle2">
              {value} Düzeni
            </Typography>
            <LayoutBoxes type={value} />
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}
