import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type ThemeStore = {
  isDark: boolean;
  mode: 'light' | 'dark';
  toogleTheme: () => void;
};
const useThemeStore = create(
  persist<ThemeStore>(
    (set, get) => ({
      mode: 'light',
      isDark: false,
      toogleTheme: () => {
        return set((state) => ({
          isDark: state.mode === 'light',
          mode: state.mode === 'light' ? 'dark' : 'light',
        }));
      },
    }),
    {
      name: 'theme-mode',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useThemeStore;
