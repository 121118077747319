import React from 'react';
import { Skeleton, Stack } from '@mui/material';

interface Props {}

export default function MapAsideWrapperTaskAssignmentPoolsItemEmpty(props: Props) {
  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ flexShrink: 0, height: 58, px: 1.25, bgcolor: 'background.default', borderRadius: 1 }}
    >
      <Skeleton variant="rounded" width={20} height={20} />
      <Skeleton variant="circular" width={30} height={30} />
      <Stack flex={1}>
        <Skeleton variant="text"></Skeleton>
        <Skeleton variant="text" width="50%"></Skeleton>
      </Stack>
      <Skeleton variant="rounded" width={25} height={15} />
      <Skeleton variant="circular" width={24} height={24} />
    </Stack>
  );
}
