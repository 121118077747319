import { Box } from '@mui/material';
//
import { CalendarAsideResponseTaskMap } from 'src/store/calendar-aside/types';
import CalendarAsideWrapperListItemPointsTasksItem from './item-points-tasks-item';

type Props = {
  tasks: CalendarAsideResponseTaskMap[];
};

export default function CalendarAsideWrapperListItemPointsTasks(props: Props) {
  const { tasks } = props;

  return (
    <Box sx={{ ml: (t) => `${t.spacing(4)}!important` }}>
      {tasks.map((task) => {
        return <CalendarAsideWrapperListItemPointsTasksItem key={task.id} task={task} />;
      })}
    </Box>
  );
}
