// ----------------------------------------------------------------------

const ROOTS = {
  DASHBOARD: '/dashboard',
  TASKS: '/tasks',
  PLANNING: '/planning',
  SETTINGS: '/settings',
};

// ----------------------------------------------------------------------

export const paths = {
  scheduler: 'https://google.com.tr/',
  // AUTH
  auth: {},
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    analytics: `${ROOTS.DASHBOARD}/analytics`,
  },
  tasks: {
    root: `${ROOTS.TASKS}`,
    create: `${ROOTS.TASKS}/create`,
    definition: `${ROOTS.TASKS}/definition`,
    rejection: `${ROOTS.TASKS}/rejection`,
  },
  map: '/map',
  calendar: '/calendar',
  settings: {
    root: ROOTS.SETTINGS,
    reasonsRejection: `${ROOTS.SETTINGS}/reasons-rejection`,
    rejection: `${ROOTS.SETTINGS}/rejection`,
    applicationSettings: `${ROOTS.SETTINGS}/application-settings`,
  },
};
