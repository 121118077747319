import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Link, Stack, Tooltip, Typography } from '@mui/material';

import { NavDataItem } from '../config-navigation';
import { RouterLink } from 'src/routes/components';
import capitalizeWords from 'src/utils/capitalize-words';

interface Props {
  items: NavDataItem[];
}

export default function LayoutDashboardNavbarVerticalMenuChildren(props: Props) {
  const { items } = props;
  const { pathname } = useLocation();

  return (
    <Stack pl={1.25}>
      {items.map((item) => {
        const active = item.path === pathname;
        return (
          <Link
            key={item.path}
            color="inherit"
            href={item.path}
            component={RouterLink}
            sx={{ textDecoration: 'none!important' }}
          >
            <Stack spacing={1.25} direction="row" alignItems="center" sx={{ height: 40, pl: 1.5 }}>
              <Stack
                width={20}
                height={20}
                flexShrink={0}
                alignItems="center"
                justifyContent="center"
                sx={{ border: '2px solid', borderColor: 'background.neutral', borderRadius: 2 }}
              >
                {active && <Box width={12} height={12} bgcolor="primary.main" borderRadius={1} />}
              </Stack>
              <Tooltip title={capitalizeWords(item.title)} placement="right">
                <Typography
                  overflow="hidden"
                  variant="subtitle2"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  textTransform="capitalize"
                >
                  {item.title}
                </Typography>
              </Tooltip>
            </Stack>
          </Link>
        );
      })}
    </Stack>
  );
}
