import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { createTheme } from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import useThemeStore from 'src/store/theme';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

export default function ThemeProviderConfigurations() {
  const { mode } = useThemeStore(useShallow((s) => s));

  const themeOptions: any = React.useMemo(
    () => ({
      typography,
      breakpoints,
      direction: 'ltr',
      shadows: shadows[mode],
      palette: palette[mode],
      shape: { borderRadius: 6 },
      customShadows: customShadows[mode],
    }),
    [mode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return theme;
}
