import { create } from 'zustand';
//
import axios, { endpoints } from 'src/utils/axios';
import { PoolsResponseType, PoolsStoreType } from './types';

const initialState: PoolsStoreType = {
  count: 0,
  items: [],
  error: false,
  loading: true,
};

const usePoolsStore = create<PoolsStoreType>(() => ({
  ...initialState,
}));

export default usePoolsStore;

const { setState } = usePoolsStore;

// REQUESTS
export const getAllPools = async () => {
  setState(initialState);
  try {
    const response = await axios.get<{ data: PoolsResponseType }>(endpoints.pools.list());
    setState(response.data.data);
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};
