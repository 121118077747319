import React from 'react';
import { LogoutCurve } from 'iconsax-react';
import { Avatar, Badge, Box, Card, Stack, Tooltip, Typography, styled } from '@mui/material';
//
import ProfileMock from 'src/store/profile';

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    width: 8,
    height: 8,
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      top: 0,
      left: 0,
      width: '100%',
      content: '""',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      border: '1px solid currentColor',
    },
  },
}));

interface Props {}

export default function LayoutNavbarProfileBox(props: Props) {
  const profileMock = ProfileMock();

  const handleLogout = () => {
    Object.entries(localStorage).forEach(([key]) => {
      if (!['maps-layout', 'theme-mode'].includes(key)) {
        localStorage.removeItem(key);
      }
    });

    window.location.reload();
  };

  return (
    <Stack
      py={1}
      px={1.5}
      spacing={1}
      flexShrink={0}
      direction="row"
      component={Card}
      alignItems="center"
      bgcolor="background.neutral"
    >
      <Stack flex={1} direction="row" alignItems="center" spacing={2}>
        <StyledBadge
          variant="dot"
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Avatar
            alt={profileMock.fullName}
            sx={{ width: 32, height: 32 }}
            src="/static/images/avatar/1.jpg"
          />
        </StyledBadge>
        <Typography variant="subtitle2">{profileMock.fullName}</Typography>
      </Stack>

      <Box
        title="Çıkış Yap"
        component={Tooltip}
        onClick={handleLogout}
        sx={{ cursor: 'pointer', color: 'text.primary' }}
      >
        <LogoutCurve variant="Bulk" size={24} />
      </Box>
    </Stack>
  );
}
