import { create } from 'zustand';
//
import axios, { endpoints } from 'src/utils/axios';
import {
  type MapsBottomTasksStore,
  type GetAllMapsBottomTasks,
  type MapsBottomTasksResponse,
  type MapsBottomTasksStoreActions,
} from './types';

const initialState: MapsBottomTasksStore = {
  // Response
  response: {
    list: [],
    totalPages: 0,
    totalElements: 0,
  },
  //
  search: '',
  error: false,
  loading: false,
  rowSelection: {},
  rowSelectionCount: 0,
  pagination: { pageIndex: 0, pageSize: 20 },
};

const useMapsBottomTasksStore = create<MapsBottomTasksStore & MapsBottomTasksStoreActions>(
  (set) => ({
    ...initialState,
    reset: () => set(initialState),
    onRowSelectionChange: (updater: any) => {
      if (typeof updater === 'function') {
        return set((s) => {
          const oldRowSelection = s.rowSelection;
          const rowSelection = updater(oldRowSelection);
          const rowSelectionCount = Object.keys(rowSelection).length;
          return { rowSelection, rowSelectionCount };
        });
      }

      const rowSelectionCount = Object.keys(updater).length;

      return set({ rowSelection: updater, rowSelectionCount });
    },
  })
);

export default useMapsBottomTasksStore;

const { setState, getState } = useMapsBottomTasksStore;

export const getMapsAllBottomTasks = async (body?: GetAllMapsBottomTasks, pagination?: boolean) => {
  setState({ loading: true });
  const state = getState();

  const newPageIndex = pagination ? state.pagination.pageIndex + 1 : 1;

  setState({ pagination: { ...state.pagination, pageIndex: newPageIndex } });

  const payload: GetAllMapsBottomTasks = {
    page: newPageIndex,
    size: initialState.pagination.pageSize,
    endDateTime: new Date(),
    ...body,
  };

  try {
    const response = await axios.post<MapsBottomTasksResponse>(endpoints.task.list, payload);
    setState((s) => ({
      response: {
        ...response.data,
        list: pagination ? [...s.response.list, ...response.data.list] : response.data.list,
        totalElements: body?.searchKey ? s.response.totalElements : response.data.totalElements,
      },
    }));
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};
