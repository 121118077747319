import * as React from 'react';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import { Avatar, Checkbox, Divider, IconButton, Stack, Typography } from '@mui/material';
//
import CalendarAsideWrapperListItemPoints from './item-points';
import { handleAllSelectedsPoints } from 'src/store/calendar-aside';
import {
  CalendarAsideResponseItem,
  CalendarAsideSelectedsPoints,
} from 'src/store/calendar-aside/types';

type Props = {
  item: CalendarAsideResponseItem;
  selectedsPoints: CalendarAsideSelectedsPoints;
};

export default function CalendarAsideTeamsWrapperListItem(props: Props) {
  const { item, selectedsPoints } = props;
  const [visible, setVisible] = React.useState(false);

  const userCheckeds = selectedsPoints[item.id]?.length > 0;

  return (
    <Stack sx={{ bgcolor: 'background.default', borderRadius: 1, overflow: 'hidden' }}>
      <Stack direction="row" spacing={1.5} alignItems="center" px={1.5} height={58}>
        <Checkbox
          disableRipple
          sx={{ px: 0 }}
          checked={userCheckeds}
          onChange={() => handleAllSelectedsPoints(item.id, userCheckeds)}
        />
        <Avatar sx={{ width: 32, height: 32 }} />
        <Stack flex={1} justifyContent="center">
          <Typography variant="subtitle2">
            {item.name}{' '}
            <Typography variant="subtitle1" component="b" color="primary.light">
              %50
            </Typography>
          </Typography>
          <Typography variant="caption">Kullanıcı Bilgi Alanı</Typography>
        </Stack>
        <IconButton size="small" onClick={() => setVisible(!visible)}>
          {visible ? <ArrowUp2 size={24} /> : <ArrowDown2 size={24} />}
        </IconButton>
      </Stack>
      <Stack px={0.75} display={visible ? 'flex' : 'none'}>
        <Divider />
        <Stack spacing={0.5} py={0.75}>
          {item.points.map((point, index) => {
            return (
              <CalendarAsideWrapperListItemPoints
                key={index}
                user={item}
                point={point}
                index={index}
                checked={Boolean(selectedsPoints[item.id]?.includes(point.id))}
              />
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}
