import React, { UIEvent } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { Chip, Divider, Stack } from '@mui/material';
import {
  MaterialReactTable,
  MRT_RowVirtualizer,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
//
// import DragCell from './drag-cell';
import TableEmptyList from './empty';
import frequencyUnitOptions from 'src/helpers/frequency-unit-options';
import { MapsBottomTasksToPlannedItem } from 'src/store/maps-bottom-tasks-to-planned/types';
import useMapsBottomTasksToPlannedStore, {
  getMapsBottomAllTasksToPlanned,
} from 'src/store/maps-bottom-tasks-to-planned';

interface Props {
  tableHeight: number;
  setVisibleItem: React.Dispatch<React.SetStateAction<MapsBottomTasksToPlannedItem | null>>;
}

export default function MapBottomWrapperTasksToPlannedTable(props: Props) {
  const { tableHeight, setVisibleItem } = props;
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = React.useRef<MRT_RowVirtualizer>(null);

  const mapsBottomTasksToPlannedStore = useMapsBottomTasksToPlannedStore(useShallow((s) => s));

  const conditionPagination =
    !mapsBottomTasksToPlannedStore.loading &&
    mapsBottomTasksToPlannedStore.response.totalPages >
      mapsBottomTasksToPlannedStore.pagination.pageIndex;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (scrollTop + clientHeight >= scrollHeight && conditionPagination) {
          getMapsBottomAllTasksToPlanned(undefined, true);
        }
      }
    },
    [conditionPagination]
  );

  // tablonun zaten aşağıya kaydırılıp kaydırılmadığını ve hemen daha fazla veri getirmesi gerekip gerekmediğini görmek için montajda bir kontrol yapar
  React.useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const columns = React.useMemo<MRT_ColumnDef<MapsBottomTasksToPlannedItem>[]>(
    () => [
      {
        size: 70,
        header: 'Task ID',
        accessorKey: 'id',
      },
      {
        header: 'Task Adı',
        accessorKey: 'name',
        muiTableBodyCellProps: { align: 'center' },
        muiTableHeadCellProps: { align: 'center' },
      },
      {
        header: 'Task Tipi',
        accessorKey: 'taskTemplate.name',
        muiTableBodyCellProps: { align: 'center' },
        muiTableHeadCellProps: { align: 'center' },
      },
      {
        header: 'Kişi/Ekip/Rol/Havuz',
        accessorKey: 'assignmentsAll',
        muiTableBodyCellProps: { align: 'center' },
        muiTableHeadCellProps: { align: 'center' },
        Cell: ({ row }) => {
          if (row.original.assignments.length === 0) {
            return <Chip size="small" variant="ghost" label={'-'} />;
          }

          const { employeeId, teamId, poolId, roleId } = row.original.assignments[0];

          let title = '';

          if (employeeId) {
            title = 'Kişi';
          }

          if (teamId) {
            title = 'Ekip';
          }

          if (roleId) {
            title = 'Rol';
          }

          if (poolId) {
            title = 'Havuz';
          }

          if (!title) return <Chip size="small" variant="ghost" label={'-'} />;

          return (
            <Chip
              size="small"
              variant="ghost"
              label={`${title}: ${row.original.assignments.length}`}
            />
          );
        },
      },
      {
        header: 'İlişkili Nokta veya Filtre',
        accessorKey: 'relatedPoint',
        muiTableBodyCellProps: { align: 'center' },
        muiTableHeadCellProps: { align: 'center' },
        Cell: ({ row }) => {
          const item = row.original;

          if (item.points?.length > 0) {
            return (
              <Chip
                size="small"
                onClick={() => setVisibleItem(item)}
                label={`Nokta Sayısı: ${item.points.length}`}
              />
            );
          }

          if (item.geoMarketingFilters.length > 0) {
            return (
              <Chip
                size="small"
                label={`Filtre: ${item.geoMarketingFilters[0].geoMarketFilterId}`}
              />
            );
          }

          return <Chip size="small" label="-" />;
        },
      },
      {
        header: 'Frekans Değer/Birim',
        accessorKey: 'timeData.frequencyValue',
        muiTableHeadCellProps: { align: 'center' },
        muiTableBodyCellProps: { align: 'center' },
        Cell: ({ row }) => {
          const { frequencyUnit, frequencyValue } = row.original.timeData;

          if (!frequencyUnit && !frequencyValue) {
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Chip size="small" variant="ghost" label={'-'} />
                <Divider orientation="vertical" flexItem />
                <Chip size="small" variant="ghost" label={'-'} />
              </Stack>
            );
          }

          const unit = frequencyUnitOptions.find((o) => o.value === frequencyUnit);

          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip size="small" label={row.original.timeData.frequencyValue} />
              <Divider orientation="vertical" flexItem />
              <Chip size="small" label={unit?.label || frequencyUnit} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    enableSorting: false,
    enableGrouping: true,
    enableTopToolbar: false,
    enablePagination: false,
    enableTableFooter: false,
    enableStickyHeader: true,
    enableRowSelection: true,
    rowVirtualizerInstanceRef,
    enableColumnActions: false,
    enableBottomToolbar: false,
    enableColumnResizing: false,
    enableColumnDragging: false,
    enableRowVirtualization: true,
    enableColumnVirtualization: true,
    getRowId: (originalRow) => `${originalRow.id}`,
    data: mapsBottomTasksToPlannedStore.response.list,
    rowCount: mapsBottomTasksToPlannedStore.response.totalElements,
    onRowSelectionChange: mapsBottomTasksToPlannedStore.onRowSelectionChange,
    renderEmptyRowsFallback: () => <TableEmptyList tableHeight={tableHeight} />,
    rowVirtualizerOptions: { overscan: mapsBottomTasksToPlannedStore.pagination.pageSize },
    state: {
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 20 },
      isLoading: mapsBottomTasksToPlannedStore.loading,
      rowSelection: mapsBottomTasksToPlannedStore.rowSelection,
      columnPinning: { left: ['mrt-row-select'], right: ['actions'] },
    },
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: { height: tableHeight },
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    muiTablePaperProps: { elevation: 0, sx: { borderRadius: '0', border: '0' } },
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 60,
        muiTableHeadCellProps: { align: 'center' },
        muiTableBodyCellProps: { align: 'center' },
      },
    },
    muiTableHeadCellProps: () => ({
      sx: { '& .Mui-TableHeadCell-Content-Wrapper': { whiteSpace: 'nowrap' } },
    }),
  });

  return <MaterialReactTable table={table} />;
}
