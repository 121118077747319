import React from 'react';
import { MoreVert } from '@mui/icons-material';
import { Checkbox, IconButton, Stack, Typography } from '@mui/material';
//
import CalendarAsideWrapperListItemPointsTasks from './item-points-tasks';
import useCalendarAsideStore, { handleSingleSelectedsPoints } from 'src/store/calendar-aside';
import {
  CalendarAsideResponseItem,
  CalendarAsideResponseItemPoints,
} from 'src/store/calendar-aside/types';

type Props = {
  index: number;
  checked: boolean;
  user: CalendarAsideResponseItem;
  point: CalendarAsideResponseItemPoints;
};

export default function CalendarAsideWrapperListItemPoints(props: Props) {
  const { index, point, user, checked } = props;

  const taskMap = useCalendarAsideStore((s) => s.response.taskMap);

  const tasks = point.taskIds.map((taskId) => taskMap[taskId]);

  return (
    <Stack
      sx={{
        p: 0.75,
        borderRadius: 1,
        bgcolor: checked ? 'background.neutral' : 'background.default',
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Checkbox
          disableRipple
          sx={{ px: 0 }}
          checked={checked}
          onChange={() => handleSingleSelectedsPoints(user.id, point.id)}
        />
        <Stack flex={1} alignItems="flex-start" sx={{ transition: '.4s ease' }}>
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            sx={{
              px: 1,
              py: 0.5,
              borderRadius: 1,
              alignItems: 'center',
              bgcolor: 'background.neutral',
            }}
          >
            <Stack
              justifyContent="center"
              sx={{
                width: 16,
                height: 17,
                textAlign: 'center',
                bgcolor: (t) => t.palette.grey[400],
                borderRadius: '50% 50% 50% 50% / 38% 38% 62% 62%',
              }}
            >
              <Typography variant="subtitle2" fontSize={12}>
                {index + 1}
              </Typography>
            </Stack>
            <Typography variant="subtitle2">{point.name}</Typography>
          </Stack>
        </Stack>
        <IconButton size="small">
          <MoreVert fontSize="small" />
        </IconButton>
      </Stack>
      <CalendarAsideWrapperListItemPointsTasks tasks={tasks} point={point} />
    </Stack>
  );
}
