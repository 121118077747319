import moment from 'moment';
import { create } from 'zustand';
//
import { CalendarAsideResponse, CalendarAsideStore } from './types';
import { CalendarStoreResponseRightItemTypesEnum } from '../calendar/types';
import { TaskDefinitionFormStoreTaskStatusEnum } from '../task-definition-form/types';

const initialState: CalendarAsideStore = {
  response: {
    users: [],
    teams: [],
    points: [],
    taskMap: {},
  },
  //
  selectedsPoints: {},
};

const useCalendarAsideStore = create<CalendarAsideStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useCalendarAsideStore;

const { setState, getState } = useCalendarAsideStore;

export const setCalendarAsideData = async (body: CalendarAsideResponse) => {
  const targetDate = moment().format('YYYY-MM-DDTHH:mm:ss');
  const createdDate = moment().subtract(15, 'minutes').format('YYYY-MM-DDTHH:mm:ss');

  const points = (body.users.length > 0 ? body.users : body.teams)
    .map((point) => point.points)
    .flat();

  const response = {
    points,
    taskMap: body.taskMap,
    users: body.users.map((user) => {
      return {
        ...user,
        points: user.points.map((point) => ({
          ...point,
          calendarRightItems: point.taskIds.map((taskId) => {
            const task = body.taskMap[taskId];
            return {
              id: task.id,
              targetDate,
              createdDate,
              name: task.name,
              taskTemplateId: 0,
              rejectReasonId: 0,
              taskTemplateName: '',
              targetCompleteDate: targetDate,
              point: {
                id: point.id,
                name: point.name,
                COORDINATE_X: point.COORDINATE_X,
                COORDINATE_Y: point.COORDINATE_Y,
                createdTaskCount: point.createdTaskCount,
              },
              assignmentData: {
                teamId: null,
                teamName: null,
                poolId: null,
                poolName: null,
                roleId: null,
                roleName: null,
                employeeId: user.id,
                employeeName: user.name,
              },
              type: CalendarStoreResponseRightItemTypesEnum.EMPLOYEE,
              status: TaskDefinitionFormStoreTaskStatusEnum.CREATED_USER_ASSIGNED,
              task: { id: task.id, name: task.name, completeDuration: task.completeDuration },

              // customize for calendar
              disabled: false,
              channelUuid: '',
              till: targetDate,
              since: createdDate,
              taskMaxCount: task.count,
              customId: `${task.id}-${point.id}`,
            };
          }),
        })),
      };
    }),
    teams: body.teams.map((team) => {
      return {
        ...team,
        points: team.points.map((point) => ({
          ...point,
          calendarRightItems: point.taskIds.map((taskId) => {
            const task = body.taskMap[taskId];
            return {
              id: task.id,
              targetDate,
              createdDate,
              name: task.name,
              taskTemplateId: 0,
              rejectReasonId: 0,
              taskTemplateName: '',
              targetCompleteDate: targetDate,
              point: {
                id: point.id,
                name: point.name,
                COORDINATE_X: point.COORDINATE_X,
                COORDINATE_Y: point.COORDINATE_Y,
                createdTaskCount: point.createdTaskCount,
              },
              assignmentData: {
                teamId: team.id,
                teamName: team.name,
                poolId: null,
                poolName: null,
                roleId: null,
                roleName: null,
                employeeId: null,
                employeeName: null,
              },
              type: CalendarStoreResponseRightItemTypesEnum.TEAM,
              status: TaskDefinitionFormStoreTaskStatusEnum.CREATED_TEAM_ASSIGN,
              task: { id: task.id, name: task.name, completeDuration: task.completeDuration },

              // customize for calendar
              disabled: false,
              channelUuid: '',
              till: targetDate,
              since: createdDate,
              taskMaxCount: task.count,
              customId: `${task.id}-${point.id}`,
            };
          }),
        })),
      };
    }),
  };

  setState({ response });
};

export const handleSingleSelectedsPoints = (userId: string, pointId: string) => {
  const state = getState();

  const userSelectedsPoints = state.selectedsPoints[userId] || [];

  const newSelectedsPoints = userSelectedsPoints.includes(pointId)
    ? userSelectedsPoints.filter((item) => item !== pointId)
    : [...userSelectedsPoints, pointId];

  if (newSelectedsPoints.length === 0) {
    const newSeledtedsPoints = { ...state.selectedsPoints };

    delete newSeledtedsPoints[userId];

    setState({ selectedsPoints: newSeledtedsPoints });
  } else {
    setState({ selectedsPoints: { ...state.selectedsPoints, [userId]: newSelectedsPoints } });
  }
};

export const handleAllSelectedsPoints = (userId: string, clear: boolean) => {
  const state = getState();

  if (clear) {
    const newSeledtedsPoints = { ...state.selectedsPoints };

    delete newSeledtedsPoints[userId];

    setState({ selectedsPoints: newSeledtedsPoints });
  } else {
    const userPointsIDS = state.response.users
      .flatMap((item) => item.points)
      .map((point) => point.id);

    setState({ selectedsPoints: { ...state.selectedsPoints, [userId]: userPointsIDS } });
  }
};
