import React from 'react';
import { Box, Stack } from '@mui/material';
//
import TaskSelectedBox from '../../task-selected-box';
import MapAsideSubWrapperEmptyDetail from '../empty-detail';
import MapAsideSubWrapperPersonTeamDetailCollapses from './collapses';
import useMapAsideSubPersonsStore from 'src/store/map-aside-sub-persons';
import MapAsideSubWrapperMultipleProcessesSelectbox from '../multiple-processes-select-box';

export default function MapAsideSubWrapperPersonTeamDetail() {
  const personDetailPoints = useMapAsideSubPersonsStore((s) => s.personDetailPoints);

  if (personDetailPoints.length === 0) {
    return <MapAsideSubWrapperEmptyDetail />;
  }

  return (
    <Stack flex={1} overflow="hidden">
      <Box p={1.5}>
        <MapAsideSubWrapperMultipleProcessesSelectbox />
      </Box>
      <Stack
        id="person-team-detail-scrollable-div"
        sx={{
          flex: 1,
          p: 1.5,
          pt: 0,
          gap: 1.5,
          overflow: 'auto',
          '&': { scrollbarWidth: 'none' },
          '&::-webkit-scrollbar': { display: 'none' },
          '& .infinite-scroll-component': { gap: 1.25, display: 'flex', flexDirection: 'column' },
        }}
      >
        <MapAsideSubWrapperPersonTeamDetailCollapses />
      </Stack>
      <TaskSelectedBox position="sticky" bottom={0} m={1.5} />
    </Stack>
  );
}
