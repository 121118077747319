import React from 'react';
import { Divider, Stack } from '@mui/material';
//
import CalendarFiltersHeader from './header';
import CalendarFiltersFooter from './footer';
import CalendarFiltersContent from './content';
import { getCalendarData } from 'src/store/calendar';
import useCalendarLayoutStore from 'src/store/calendar-layout';
import { CalendarLayoutStore } from 'src/store/calendar-layout/types';

const clearState: CalendarLayoutStore['filters'] = {
  statuses: [],
  selectedsTeams: [],
  selectedsPools: [],
  selectedsPersons: [],
};

export default function CalendarFiltersWrapper() {
  const filters = useCalendarLayoutStore((s) => s.filters);
  const [tempFilters, setTempFilters] = React.useState({ ...filters });

  const onClose = React.useCallback(() => {
    setTempFilters(filters);
    useCalendarLayoutStore.setState({ filtersVisible: false });
  }, [filters]);

  const handleClear = React.useCallback(() => {
    setTempFilters(clearState);
    useCalendarLayoutStore.getState().reset();

    getCalendarData();
  }, []);

  const handleSave = React.useCallback(() => {
    useCalendarLayoutStore.setState({ filtersVisible: false, filters: tempFilters });

    // Fetch data
    getCalendarData();
  }, [tempFilters]);

  return (
    <Stack width={320} height="100dvh">
      <CalendarFiltersHeader onClose={onClose} />
      <Divider />
      <CalendarFiltersContent tempFilters={tempFilters} setTempFilters={setTempFilters} />
      <Divider />
      <CalendarFiltersFooter handleClear={handleClear} handleSave={handleSave} />
    </Stack>
  );
}
