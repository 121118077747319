import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { Avatar, Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import {
  Moon,
  ElementPlus,
  ExternalDrive,
  Menu as MenuIcon,
  DirectNotification,
} from 'iconsax-react';

//
import { paths } from 'src/routes/paths';
import useThemeStore from 'src/store/theme';
import ProfileMock from 'src/store/profile';
import { layoutMapConfig } from 'src/config-global';

type Props = {
  actionBtn?: React.ReactNode;
};

export default function LayoutMapCalendarHeader(props: Props) {
  const navigate = useNavigate();
  const profileMock = ProfileMock();

  const { isDark, toogleTheme } = useThemeStore(useShallow((s) => s));

  return (
    <Stack
      spacing={1}
      direction="row"
      component="header"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        px: 2.25,
        overflow: 'hidden',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'divider',
        height: layoutMapConfig.headerSize,
      }}
    >
      {props.actionBtn || (
        <Button
          onClick={() => navigate(paths.dashboard.root)}
          startIcon={<MenuIcon size={24} variant="Bulk" />}
        >
          Dashboard
        </Button>
      )}

      <Stack direction="row" alignItems="center" spacing={1.5}>
        <ToggleButtonGroup size="small" color="primary">
          <ToggleButton value="btn-1">
            <ExternalDrive size={20} variant="Bulk" />
          </ToggleButton>
          <ToggleButton value="btn-2">
            <DirectNotification size={20} variant="Bulk" />
          </ToggleButton>
          <ToggleButton value="btn-3">
            <ElementPlus size={20} variant="Bulk" />
          </ToggleButton>
          <ToggleButton value="btn-4" onClick={toogleTheme} selected={isDark}>
            <Moon variant={isDark ? 'Bulk' : 'Bold'} size={20} />
          </ToggleButton>
        </ToggleButtonGroup>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar>{profileMock.fullName.charAt(0)}</Avatar>
          <Stack>
            <Typography variant="subtitle2">{profileMock.fullName}</Typography>
            <Typography variant="body2" color="text.disabled">
              {profileMock.email}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
