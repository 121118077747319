import _ from 'lodash';
import moment from 'moment';
import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import { UsersType } from '../users/types';
import axios, { endpoints } from 'src/utils/axios';
import useCalendarLayoutStore from '../calendar-layout';
import {
  CalendarStore,
  CalendarOnDnDMouseUp,
  CalendarStoreResponse,
  CalendarCreateBulkPayload,
  CalendarResponseLeftItemEnum,
  CalendarStoreResponseRightItem,
} from './types';

const initialState: CalendarStore = {
  loading: false,
  responseLeft: [],
  responseRight: [],
  createBulkLoading: false,
};

const useCalendarStore = create<CalendarStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useCalendarStore;

const { setState, getState } = useCalendarStore;

export const getCalendarData = async (date?: string) => {
  setState({ loading: true });

  const calendarLayoutStore = useCalendarLayoutStore.getState();

  const newDate = date || useCalendarLayoutStore.getState().date;

  useCalendarLayoutStore.setState({ date: newDate });

  const teams = calendarLayoutStore.filters.selectedsTeams;
  const users = calendarLayoutStore.filters.selectedsPersons;

  const responseLeft = [];
  const responseRight = [];

  const sendDate = moment(newDate).format('YYYY-MM-DD');

  try {
    for (const user of users) {
      responseLeft.push({
        uuid: user.id,
        employeItem: user,
        title: user.fullName,
        type: CalendarResponseLeftItemEnum.EMPLOYEE,
      });

      // Burası calendarın sağ tarafındaki taskları getirir (user bazlı)
      const res = await getTaskItemForExactlyEmployee(
        user.id,
        user.teams.map((team) => team.id),
        sendDate
      );

      responseRight.push(
        ...res.map((item) => ({
          ...item,
          disabled: Boolean(item.assignmentData.teamId),
          channelUuid: `${CalendarResponseLeftItemEnum.EMPLOYEE}-${user.id}`,
        }))
      );
    }

    for (const team of teams) {
      responseLeft.push({
        uuid: team.id,
        teamItem: team,
        isOpen: true, // false olursa altındaki userlerin positionları kayboluyor ve error veriyor.
        groupTree: true,
        title: team.name,
        type: CalendarResponseLeftItemEnum.TEAM,
      });

      // Burası calendarın sol tarafındaki team'ın altındaki userları getirir
      const res1 = await axios.get<{ data: UsersType[] }>(
        endpoints.teams.findListWithUsers(team.id)
      );

      responseLeft.push(
        ...res1.data.data.map((user) => ({
          uuid: user.id,
          teamItem: team,
          employeItem: user,
          title: user.fullName,
          type: CalendarResponseLeftItemEnum.TEAM_EMPLOYEE,
          parentChannelUuid: `${CalendarResponseLeftItemEnum.TEAM}-${team.id}`,
        }))
      );

      // Burası calendarın sağ tarafındaki taskları getirir (team içindeki user bazlı)
      for (const user of res1.data.data) {
        const res = await getTaskItemForExactlyEmployee(user.id, [], sendDate);

        responseRight.push(
          ...res.map((item) => ({
            ...item,
            disabled: true,
            channelUuid: `${CalendarResponseLeftItemEnum.TEAM_EMPLOYEE}-${user.id}`,
          }))
        );
      }

      // Burası calendarın sağ tarafındaki taskları getirir (team bazlı)
      const res = await getTaskItemForExactlyTeam(team.id, sendDate);

      responseRight.push(
        ...res.map((item) => ({
          ...item,
          disabled: false,
          channelUuid: `${CalendarResponseLeftItemEnum.TEAM}-${team.id}`,
        }))
      );
    }

    setState({
      responseRight: responseRight.map((item) => {
        const newDate = moment(item.targetDate);
        const since = newDate.format('YYYY-MM-DDTHH:mm:ss');
        const till = moment(item.targetCompleteDate).format('YYYY-MM-DDTHH:mm:ss');

        return { ...item, since, till };
      }),
      responseLeft: responseLeft.map((item) => ({ ...item, uuid: `${item.type}-${item.uuid}` })),
    });
  } catch (error) {
  } finally {
    setState({ loading: false });
  }
};

const getTaskItemForExactlyEmployee = async (
  employeeId: string,
  teams: string[],
  calendarDate: string
) => {
  try {
    const res = await axios.post<CalendarStoreResponse>(endpoints.taskItem.filter, {
      employeeId,
      teams: teams,
      calendarDate,
    });

    return res.data.list.map((item) => ({
      ...item,
      till: item.targetCompleteDate,
      since: item.targetDate,
    }));
  } catch (error) {
    throw error;
  }
};

const getTaskItemForExactlyTeam = async (teamId: string, calendarDate: string) => {
  try {
    const res = await axios.post<CalendarStoreResponse>(endpoints.taskItem.filter, {
      teamId,
      calendarDate,
    });

    return res.data.list.map((item) => ({
      ...item,
      till: item.targetCompleteDate,
      since: item.targetDate,
    }));
  } catch (error) {
    throw error;
  }
};

export const calendarOnDnDMouseUp = (body: CalendarOnDnDMouseUp): Boolean => {
  return !body.data.disabled;
};

export const calendarOnDnDSuccess = async (newItem: CalendarStoreResponseRightItem) => {
  console.log('calendarOnDnDSuccess', newItem);
  const state = getState();

  if (typeof newItem.id !== 'number') {
    newItem.id = Math.random().toString(36).substring(7);
  }

  // Bıraktığımızda bırakılan taskı tüm yerlerden kaldırdık.
  const responseRight = [...state.responseRight].filter((item) => item.id !== newItem.id);

  const completeDuration = moment(newItem.till).diff(moment(newItem.since), 'minutes');

  const newItemNewChannelType = newItem.channelUuid?.slice(0, -37);

  // Yeni taskı oluşturduk
  const createNewItem = { ...newItem, task: { ...newItem.task, completeDuration } };

  if (newItemNewChannelType === CalendarResponseLeftItemEnum.TEAM) {
    responseRight.push(createNewItem);

    state.responseLeft.forEach((item) => {
      if (item.parentChannelUuid?.slice(-36) === newItem.channelUuid?.slice(-36)) {
        responseRight.push({
          ...createNewItem,
          disabled: true,
          channelUuid: `${CalendarResponseLeftItemEnum.EMPLOYEE}-${item.uuid.slice(-36)}`,
        });
      }
    });
  } else if (newItemNewChannelType === CalendarResponseLeftItemEnum.EMPLOYEE) {
    state.responseLeft.forEach((item) => {
      if (item.uuid?.slice(-36) === newItem.channelUuid?.slice(-36)) {
        responseRight.push({
          ...createNewItem,
          disabled: item.uuid === newItem.channelUuid ? false : true,
          channelUuid: `${
            item.uuid === newItem.channelUuid
              ? CalendarResponseLeftItemEnum.EMPLOYEE
              : CalendarResponseLeftItemEnum.TEAM_EMPLOYEE
          }-${item.uuid.slice(-36)}`,
        });
      }
    });
  } else if (newItemNewChannelType === CalendarResponseLeftItemEnum.TEAM_EMPLOYEE) {
    state.responseLeft.forEach((item) => {
      if (item.uuid?.slice(-36) === newItem.channelUuid?.slice(-36)) {
        responseRight.push({
          ...createNewItem,
          disabled: item.uuid === newItem.channelUuid,
          channelUuid: `${
            item.uuid === newItem.channelUuid
              ? CalendarResponseLeftItemEnum.TEAM_EMPLOYEE
              : CalendarResponseLeftItemEnum.EMPLOYEE
          }-${item.uuid.slice(-36)}`,
        });
      }
    });
  }

  calendarPointsBetweenWay(newItem);

  setState({ responseRight });
};

export const remainingTaskCount = (customId: string, taskMaxCount: number) => {
  const state = getState();

  const count = state.responseRight.filter(
    (item) => !item.disabled && item.customId === customId
  ).length;

  return taskMaxCount - count;
};

export const calendarCreateBulk = async () => {
  const state = getState();
  setState({ createBulkLoading: true });

  const sendData: CalendarCreateBulkPayload[] = state.responseRight
    .filter((item) => !item.disabled)
    .map((item) => {
      const newItem = {
        id: typeof item.id === 'string' ? null : item.id,
        task: { id: item.task.id },
        point: { id: item.point.id },
        targetDate: item.since as string,
        targetCompleteDate: item.till as string,
        assignmentData: {
          teamId: '',
          teamName: '',
          poolId: '',
          poolName: '',
          roleId: '',
          roleName: '',
          employeeId: '',
          employeeName: '',
        },
      };

      const uuid = item.channelUuid?.slice(-36) || '';

      if (item.channelUuid?.slice(0, -37) === CalendarResponseLeftItemEnum.TEAM) {
        const team = state.responseLeft.find((item) => item.teamItem?.id === uuid);
        return {
          ...newItem,
          assignmentData: {
            ...newItem.assignmentData,
            teamId: team?.teamItem?.id as string,
            teamName: team?.teamItem?.name as string,
          },
        };
      }

      if (item.channelUuid?.slice(0, -37) === CalendarResponseLeftItemEnum.EMPLOYEE) {
        const employee = state.responseLeft.find((item) => item.employeItem?.id === uuid);
        return {
          ...newItem,
          assignmentData: {
            ...newItem.assignmentData,
            employeeId: employee?.employeItem?.id as string,
            employeeName: employee?.employeItem?.fullName as string,
          },
        };
      }

      return newItem;
    });

  try {
    await axios.post(endpoints.taskItem.createBulk, sendData);
    await getCalendarData();
    enqueueSnackbar('Plan oluşturuldu.', { variant: 'success' });
  } catch (error) {
    enqueueSnackbar('Plan oluşturulamadı.', { variant: 'error' });
  } finally {
    setState({ createBulkLoading: false });
  }
};

const calendarPointsBetweenWay = (newItem: CalendarStoreResponseRightItem) => {
  const avaregeSpeed = 50; // km/saat
  const haversineDistance = (x1: number, y1: number, x2: number, y2: number) => {
    const R = 6371; // Dünya'nın yarıçapı (kilometre cinsinden)

    // Enlem ve boylamları radian cinsine çevir
    const dLat = ((x2 - x1) * Math.PI) / 180;
    const dLon = ((y2 - y1) * Math.PI) / 180;

    // Başlangıç noktası enlemi ve varış noktası enlemini radian cinsine çevir
    const x1Rad = (x1 * Math.PI) / 180;
    const x2Rad = (x2 * Math.PI) / 180;

    // Haversine formülü
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(x1Rad) * Math.cos(x2Rad);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Mesafe
    const distance = R * c;
    return distance;
  };

  const state = getState();
  //
  const currentChannel = state.responseLeft.find((item) => item.uuid === newItem.channelUuid);
  const currentChannelRightItems = [...state.responseRight].filter((item) => {
    return item.channelUuid === currentChannel?.uuid;
  });

  const currentChannelRightItemsSorted = _.map(
    _.sortBy(currentChannelRightItems, (o) => moment(o.since).unix())
  );

  const currentChannelRightItemsSortedIndex = currentChannelRightItemsSorted.findIndex(
    (item) => item.id === newItem.id
  );

  const previousItem = currentChannelRightItemsSorted[currentChannelRightItemsSortedIndex - 1];
  const nextItem = currentChannelRightItemsSorted[currentChannelRightItemsSortedIndex + 1];

  if (previousItem) {
    const distance = haversineDistance(
      previousItem.point.COORDINATE_X,
      previousItem.point.COORDINATE_Y,
      newItem.point.COORDINATE_X,
      newItem.point.COORDINATE_Y
    );

    enqueueSnackbar(
      `${previousItem.point.name} - ${newItem.point.name} arası yolculuk süresi: ${Math.ceil(
        (distance / avaregeSpeed) * 60
      )} dakika (${distance.toFixed(1)} km)`
    );
  }

  if (nextItem) {
    const distance = haversineDistance(
      newItem.point.COORDINATE_X,
      newItem.point.COORDINATE_Y,
      nextItem.point.COORDINATE_X,
      nextItem.point.COORDINATE_Y
    );

    enqueueSnackbar(
      `${newItem.point.name} - ${nextItem.point.name} arası yolculuk süresi: ${Math.ceil(
        (distance / avaregeSpeed) * 60
      )} dakika (${distance.toFixed(1)} km)`
    );
  }
};
