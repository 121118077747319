import React from 'react';
import { SearchNormal, CloseCircle } from 'iconsax-react';
import { Box, Chip, CircularProgress, IconButton, Stack } from '@mui/material';

interface Props {
  count?: number;
  search: string;
  loading?: boolean;
  placeholder?: string;
  setSearch: (value: string) => void;
}

export default function MapAsideWrapperSearch(props: Props) {
  const { search, setSearch, placeholder, loading, count } = props;

  const renderAction = () => {
    if (loading) {
      return <CircularProgress size={20} />;
    }

    if (search && count !== undefined) {
      return (
        <Stack spacing={1} direction="row" alignItems="center">
          <IconButton color="error" size="small" onClick={() => setSearch('')}>
            <CloseCircle size={20} variant="Bulk" />
          </IconButton>
          <Chip size="small" variant="ghost" color="error" label={count} />
        </Stack>
      );
    }

    if (count !== undefined) {
      return <Chip size="small" variant="ghost" color="error" label={count} />;
    }

    return null;
  };

  return (
    <Stack
      pr={1}
      height={40}
      flexShrink={0}
      direction="row"
      alignItems="center"
      sx={{ bgcolor: 'background.default' }}
    >
      <IconButton disabled>
        <SearchNormal size={20} variant="Bulk" />
      </IconButton>
      <Box
        type="text"
        value={search}
        component="input"
        onChange={(e) => setSearch(e.target.value)}
        placeholder={placeholder || 'Kullanıcı veya Ekip Ara'}
        sx={{
          flex: 1,
          height: 1,
          border: 'none',
          outline: 'none',
          color: 'text.primary',
          bgcolor: 'background.default',
        }}
      />
      {renderAction()}
    </Stack>
  );
}
