import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  description: string;
}

export default function CalendarAsidePersonsWrapperEmpty(props: Props) {
  const { title, description } = props;
  return (
    <Stack
      spacing={3}
      sx={{
        flex: 1,
        height: 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="img"
        src="/img/calendar-aside-empty.svg"
        sx={{ width: 'auto', height: 200, objectFit: 'contain' }}
      />
      <Stack spacing={1.5}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Stack>
    </Stack>
  );
}
