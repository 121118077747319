import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import axios, { endpoints } from 'src/utils/axios';
import useBaseWorkspaceStore from '../base-workspaces';
import { getSearchMapPointMarkers } from '../map-points-marker';
import { MapPointSearchResponse, MapPointsStore } from './types';

const initialState: MapPointsStore = {
  search: '',
  searchLoading: true,
  paginationLoading: false,
  selectedGeomarketingFilter: null,
  //
  response: {
    page: 0,
    data: [],
    count: 0,
    size: 20,
    totalPages: 0,
  },
};
const useMapPointsStore = create<MapPointsStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useMapPointsStore;

const { getState, setState } = useMapPointsStore;

export const getMapsSearchPoint = async (search: string, hasPagination?: boolean) => {
  const state = getState();

  const baseWorkspaceStore = useBaseWorkspaceStore.getState();

  const json = state.selectedGeomarketingFilter
    ? JSON.stringify({
        query: JSON.parse(state.selectedGeomarketingFilter.filter_query).query,
      })
    : '';

  const newPage = hasPagination ? state.response.page + 1 : 1;
  const payloadIndex = `gvg.${baseWorkspaceStore.baseWorkspace?.availability.collectionName}`;

  try {
    const res = await axios.post<MapPointSearchResponse>(
      endpoints.geomarketingSearchPoints(newPage, state.response.size),
      {
        json,
        term: `*${search}*`,
        index: payloadIndex,
      }
    );

    if (hasPagination) {
      setState((prev) => ({
        response: {
          ...res.data,
          size: state.response.size,
          data: [...prev.response.data, ...res.data.data],
        },
      }));
    } else {
      setState({ response: { ...res.data, size: state.response.size } });
      await getSearchMapPointMarkers(payloadIndex, search, json, res.data.count);
    }
  } catch (error) {
    enqueueSnackbar('Noktaları getirme işlemi sırasında bir hata oluştu.', { variant: 'error' });
  } finally {
    setState({ searchLoading: false, paginationLoading: false });
  }
};
