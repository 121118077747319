import React from 'react';
import { Button, Stack } from '@mui/material';
import { Filter, Trash } from 'iconsax-react';

type Props = {
  handleClear(): void;
  handleSave(): void;
};
export default function CalendarFiltersFooter(props: Props) {
  const { handleClear, handleSave } = props;
  return (
    <Stack p={2.5} spacing={1.5} sx={{ '& > button': { justifyContent: 'space-between' } }}>
      <Button
        color="error"
        variant="contained"
        onClick={handleClear}
        endIcon={<Trash size={20} variant="Bulk" />}
      >
        Filtreyi Temizle
      </Button>
      <Button
        variant="contained"
        onClick={handleSave}
        endIcon={<Filter size={20} variant="Bulk" />}
      >
        Filtreyi Uygula
      </Button>
    </Stack>
  );
}
