import { matchPath, useLocation } from 'react-router-dom';
//
import { NavDataItem } from 'src/layouts/dashboard/navbar/config-navigation';

// ----------------------------------------------------------------------

type ReturnType = boolean;

export function useActiveLink(path: string, deep = true): ReturnType {
  const { pathname } = useLocation();

  const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false;

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;

  return deep ? deepActive : normalActive;
}

export function getMenuActiveParentPath(pathname: string, items: NavDataItem[]) {
  let lastPathParent = '';

  items.forEach((subMenu: NavDataItem) => {
    if (
      subMenu.path === pathname ||
      (subMenu.children && subMenu.children.some((subSubMenu) => subSubMenu.path === pathname))
    ) {
      lastPathParent = subMenu.path;
    }
  });

  return lastPathParent;
}
