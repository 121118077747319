import React from 'react';
//
import LayoutDashboard from 'src/layouts/dashboard';
//
const Page404 = React.lazy(() => import('src/pages/error'));

export const mainRoutes = [
  {
    element: <LayoutDashboard />,
    children: [{ path: '404', element: <Page404 /> }],
  },
];
