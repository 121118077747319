import { create } from 'zustand';
//
import axios, { endpoints } from 'src/utils/axios';
import { RolesResponseType, RolesStoreType } from './types';

const initialState: RolesStoreType = {
  count: 0,
  items: [],
  error: false,
  loading: true,
};

const useRolesStore = create<RolesStoreType>(() => ({
  ...initialState,
}));

export default useRolesStore;

const { setState } = useRolesStore;

// REQUESTS
export const getAllRoles = async () => {
  setState(initialState);
  try {
    const response = await axios.get<{ data: RolesResponseType; status: boolean }>(
      endpoints.roles.list()
    );
    setState(response.data.data);
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};
