import { create } from 'zustand';
//
import axios from 'src/utils/axios';
import { getAllPools } from '../pools';
import { getAllRoles } from '../roles';
import { getAllTasks } from '../tasks';
import { getAllTeams } from '../teams';
import { getAllUsers } from '../users';
import { BASE_API } from 'src/config-global';
import useMapPointsMarkerStore from '../map-points-marker';
import { BaseWorkspaceStore, WorkspaceItem } from './types';
import { getMapsAsidePoolsSearch } from '../map-aside-pools';
import { getAllTaskDefinitions } from '../task-definition-list';
import { getAllAvailableFunctions } from '../available-functions';
import { getAllGeomarketingFilters } from '../geomarketing-filters';
import useMapPointsStore, { getMapsSearchPoint } from '../map-points';
import useMapAsideTeamsStore, { getMapsAsideTeamsSearch } from '../map-aside-teams';
import useMapsBottomTasksStore, { getMapsAllBottomTasks } from '../maps-bottom-tasks';
import useMapAsidePersonsStore, { getMapsAsidePersonsSearch } from '../map-aside-persons';
import useMapsBottomTasksToPlannedStore, {
  getMapsBottomAllTasksToPlanned,
} from '../maps-bottom-tasks-to-planned';

const initialState: BaseWorkspaceStore = {
  loading: true,
  baseWorkspace: null,
};

const useBaseWorkspaceStore = create<BaseWorkspaceStore>(() => ({ ...initialState }));

export default useBaseWorkspaceStore;

const { setState } = useBaseWorkspaceStore;

export const getBaseWorkspace = async (mapLayout?: boolean) => {
  // TODO: Micro Frontenden sonra incelenecek
  if (mapLayout) {
    useMapAsideTeamsStore.getState().reset();
    useMapAsidePersonsStore.getState().reset();
    //
    useMapPointsStore.getState().reset();
    useMapPointsMarkerStore.getState().reset();
    //
    useMapsBottomTasksStore.getState().reset();
    useMapsBottomTasksToPlannedStore.getState().reset();
  }

  try {
    const res = await axios.get(
      `${BASE_API}import2db/api/rest/v2/workspace/published/?limit=${50}&timestamp=${new Date().getTime()}`
    );

    if (res.status === 204) {
      return false;
    }

    const baseWorkspace = res.data.data.docs.find((doc: WorkspaceItem) => {
      return doc.customerDataType === 0 && doc.state === 1;
    });

    setState({ baseWorkspace });

    await new Promise((resolve) => {
      getAllGeomarketingFilters();
      setTimeout(() => {
        if (mapLayout) {
          getMapsSearchPoint('');
          getMapsAllBottomTasks();
          getMapsAsidePersonsSearch('');
          getMapsAsideTeamsSearch('');
          getMapsAsidePoolsSearch('');
          getMapsBottomAllTasksToPlanned();
        } else {
          getAllTasks();
          getAllUsers();
          getAllTeams();
          getAllRoles();
          getAllPools();
          getAllTaskDefinitions();
          getAllAvailableFunctions();
        }

        resolve(true);
      }, 1000);
    });

    return Boolean(baseWorkspace);
  } catch (error) {
    throw error;
  } finally {
    setState({ loading: false });
  }
};
