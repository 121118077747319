import React from 'react';
//
import LayoutDashboard from 'src/layouts/dashboard';
//
const DashboardPage = React.lazy(() => import('src/pages/dashboard/index'));
const DashboardAnalyticsPage = React.lazy(() => import('src/pages/dashboard/analytics'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: <LayoutDashboard />,
    children: [
      { element: <DashboardPage />, index: true },
      { path: 'analytics', element: <DashboardAnalyticsPage /> },
    ],
  },
];
