import { ChannelWithPosition, DragMouseUp } from '@nessprim/planby-pro/dist/Epg/helpers/types';
//
import { UsersType } from '../users/types';
import { TeamsType } from '../teams/types';
import { TaskItemAssignmentData } from '../tasks/types';
import { TaskDefinitionFormStoreTaskStatusEnum } from '../task-definition-form/types';

export type CalendarStore = {
  loading: boolean;
  createBulkLoading: boolean;
  responseLeft: CalendarStoreResponseLeftItem[];
  responseRight: CalendarStoreResponseRightItem[];
};

export type CalendarStoreResponse = {
  totalPages: number;
  totalElements: number;
  list: CalendarStoreResponseRightItem[];
};

export type CalendarStoreResponseRightItem = {
  id: number | string;
  targetDate: string;
  createdDate: string;
  name: string | null;
  taskTemplateId: number;
  taskTemplateName: string;
  targetCompleteDate: string;
  rejectReasonId: number | null;
  assignmentData: TaskItemAssignmentData;
  type: CalendarStoreResponseRightItemTypesEnum;
  status: TaskDefinitionFormStoreTaskStatusEnum;
  task: { id: number; name: string; completeDuration: number };
  point: {
    id: string;
    name: string;
    COORDINATE_X: number;
    COORDINATE_Y: number;
    createdTaskCount: number;
  };

  // customize for calendar
  till?: string;
  since?: string;
  index?: number;
  customId?: string;
  disabled?: boolean;
  channelUuid?: string;
  taskMaxCount?: number;
  channelIndex?: number;
  channelPosition?: ChannelWithPosition['position'];
};

export enum CalendarStoreResponseRightItemTypesEnum {
  TEAM = 'TEAM',
  EMPLOYEE = 'EMPLOYEE',
}

//
export enum CalendarResponseLeftItemEnum {
  TEAM = 'TEAM',
  EMPLOYEE = 'EMPLOYEE',
  TEAM_EMPLOYEE = 'TEAM_EMPLOYEE',
}
export type CalendarStoreResponseLeftItem = {
  uuid: string;
  title: string;
  isOpen?: boolean;
  groupTree?: boolean;
  parentChannelUuid?: string;
  type: CalendarResponseLeftItemEnum;

  teamItem?: TeamsType;
  employeItem?: UsersType;
};

export type CalendarOnDnDMouseUp = {
  since: DragMouseUp['since'];
  till: DragMouseUp['till'];
  id: DragMouseUp['id'];
  data: CalendarStoreResponseRightItem;
};

export type CalendarCreateBulkPayload = {
  id: number | null;
  targetDate: string;
  task: { id: number };
  point: { id: string };
  targetCompleteDate: string;
  assignmentData: TaskItemAssignmentData;
};
