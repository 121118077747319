import React from 'react';
import { SearchNormal } from 'iconsax-react';
import { Box, Chip, Stack } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
//
import MapBottomWrapperTasksHeaderTabs from '../tabs';
import useMapsBottomTasksStore, { getMapsAllBottomTasks } from 'src/store/maps-bottom-tasks';

interface Props {}

export default function MapBottomWrapperTasksHeader(props: Props) {
  const searchRef = React.useRef<any>(null);
  const mapsBottomTasksStore = useMapsBottomTasksStore(useShallow((s) => s));

  const onChangeSearch = (search: string) => {
    useMapsBottomTasksStore.setState({ search });

    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    searchRef.current = setTimeout(() => {
      getMapsAllBottomTasks({
        page: 1,
        searchBy: 'name',
        searchKey: search,
        size: mapsBottomTasksStore.pagination.pageSize,
      });
    }, 500);
  };

  return (
    <Stack height={96} flexShrink={0} px={3} pt={1.5} justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <SearchNormal size={20} variant="Bulk" />
        <Box
          type="text"
          component="input"
          placeholder="Task adına göre ara..."
          value={mapsBottomTasksStore.search}
          onChange={(e) => onChangeSearch(e.target.value)}
          sx={{
            px: 1,
            height: 36,
            fontSize: 12,
            border: 'none',
            width: 'auto',
            outline: 'none',
            fontWeight: 600,
            minWidth: 'auto',
            color: 'text.primary',
            bgcolor: 'background.default',
            '::placeholder': { color: 'text.primary' },
          }}
        />
        <Chip
          size="small"
          variant="ghost"
          color="primary"
          label={`Listelenen: ${mapsBottomTasksStore.response.list.length}`}
        />
      </Stack>
      <MapBottomWrapperTasksHeaderTabs />
    </Stack>
  );
}
