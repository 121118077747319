import { Box } from '@mui/material';
//
import CalendarAsideWrapperListItemPointsTasksItem from './item-points-tasks-item';
import {
  CalendarAsideResponseTaskMap,
  CalendarAsideResponseItemPoints,
} from 'src/store/calendar-aside/types';

type Props = {
  tasks: CalendarAsideResponseTaskMap[];
  point: CalendarAsideResponseItemPoints;
};

export default function CalendarAsideWrapperListItemPointsTasks(props: Props) {
  const { tasks, point } = props;

  return (
    <Box sx={{ ml: (t) => `${t.spacing(4)}!important` }}>
      {tasks.map((task) => {
        return (
          <CalendarAsideWrapperListItemPointsTasksItem key={task.id} task={task} point={point} />
        );
      })}
    </Box>
  );
}
