import React from 'react';
import { ArrowUp2, ArrowDown2 } from 'iconsax-react';
import {
  Box,
  Stack,
  Divider,
  Collapse,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
//
import CollapseItem from './collapse-item';
import axios, { endpoints } from 'src/utils/axios';
import { CalendarStoreResponse } from 'src/store/calendar/types';
import { MapAsidePersonsListItem } from 'src/store/map-aside-persons/types';
import { MapAsideSubPersonsStoreDetailPoints } from 'src/store/map-aside-sub-persons/types';
import { enqueueSnackbar } from 'notistack';

interface Props {
  personDetail: MapAsidePersonsListItem;
  point: MapAsideSubPersonsStoreDetailPoints;
}

export default function MapAsideSubWrapperPersonTeamDetailCollapse(props: Props) {
  const { point, personDetail } = props;
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<CalendarStoreResponse>({
    list: [],
    totalPages: 0,
    totalElements: 0,
  });
  const [loading, setLoading] = React.useState(false);

  const handleVisible = async () => {
    const newVisible = !visible;
    setVisible(newVisible);

    if (visible) {
      setData({ list: [], totalPages: 0, totalElements: 0 });
      return;
    }

    setLoading(true);

    try {
      const res = await axios.post<CalendarStoreResponse>(endpoints.taskItem.filter, {
        pointId: point.id,
        employeeId: personDetail.id,
      });
      setData(res.data);
    } catch (error) {
      enqueueSnackbar('Bir hata oluştu', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={0.75}>
        <Typography variant="caption" fontWeight="600" color="text.secondary">
          {point.name}
        </Typography>
        <Divider sx={{ flex: 1 }} orientation="horizontal" />
        <IconButton size="small" onClick={handleVisible} disabled={loading}>
          {loading ? (
            <CircularProgress size={16} />
          ) : (
            <>{visible ? <ArrowUp2 size={16} /> : <ArrowDown2 size={16} />}</>
          )}
        </IconButton>
      </Stack>
      <Collapse in={visible} unmountOnExit>
        <Stack spacing={0.75} pt={1.25}>
          {data.list.map((item) => {
            return <CollapseItem key={item.id} item={item} />;
          })}
        </Stack>
      </Collapse>
    </Box>
  );
}
