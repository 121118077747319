import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface Props {}

export default function MapAsideWrapperEmptyDetail(props: Props) {
  return (
    <Stack
      spacing={3}
      sx={{
        flex: 1,
        height: 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="img"
        src="/img/left-menu-list-empty.svg"
        sx={{ width: 258, height: 240, objectFit: 'contain' }}
      />
      <Typography variant="h6">Herhangi bir sonuç bulunamadı</Typography>
    </Stack>
  );
}
