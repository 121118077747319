import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import axios, { endpoints } from 'src/utils/axios';
import useMapAsidePersonsStore from '../map-aside-persons';
import useMapAsidePoolsStore from '../map-aside-pools';
import { MapAsideTeamsStore, MapAsideTeamsListItem, MapAsideTeamsResponse } from './types';

const initialState: MapAsideTeamsStore = {
  search: '',
  loading: true,
  selectedItems: [],
  searchLoading: false,
  paginationLoading: false,

  //
  response: {
    count: 0,
    items: [],
    totalPages: 0,
    totalItems: 0,
  },
};

const useMapAsideTeamsStore = create<MapAsideTeamsStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useMapAsideTeamsStore;

const { setState, getState } = useMapAsideTeamsStore;

export const getMapsAsideTeamsSearch = async (search: string, hasPagination?: boolean) => {
  try {
    const res = await axios.get<{ data: MapAsideTeamsResponse }>(
      endpoints.teams.list(20, 0, search)
    );
    setState({ response: res.data.data });
  } catch (error) {
    enqueueSnackbar('Ekipler getirilirken bir hata oluştu.', { variant: 'error' });
  } finally {
    setState({ loading: false, searchLoading: false, paginationLoading: false });
  }
};

export const onChangeMapAsideTeamsChecked = (item: MapAsideTeamsListItem, checked: boolean) => {
  const state = getState();

  // Seçilen kişileri temizle
  if (useMapAsidePersonsStore.getState().selectedItems.length > 0) {
    useMapAsidePersonsStore.setState({ selectedItems: [] });
    enqueueSnackbar('Ekipler de işlem yaptığınız için seçilen Kişiler temizlendi.', {
      variant: 'default',
    });
  }

  // Seçilen havuzları temizle
  if (useMapAsidePoolsStore.getState().selectedItems.length > 0) {
    useMapAsidePoolsStore.setState({ selectedItems: [] });
    enqueueSnackbar('Ekipler de işlem yaptığınız için seçilen Havuzlar temizlendi.', {
      variant: 'default',
    });
  }

  const selectedItems = checked
    ? [...state.selectedItems, item]
    : state.selectedItems.filter((i) => i.id !== item.id);

  setState({ selectedItems });
};
