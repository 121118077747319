import React from 'react';
import { Drawer } from '@mui/material';
//
import useCalendarLayoutStore from 'src/store/calendar-layout';
import CalendarFiltersWrapper from 'src/components/calendar/filters-wrapper';

export default function LayoutCalendarFilters() {
  const filtersVisible = useCalendarLayoutStore((s) => s.filtersVisible);

  return (
    <Drawer
      anchor="right"
      open={filtersVisible}
      ModalProps={{ keepMounted: true }}
      PaperProps={{
        sx: {
          bgcolor: 'background.default',
          borderRadius: (t) => `${t.spacing(1)} 0 0 ${t.spacing(1)}`,
        },
      }}
    >
      <CalendarFiltersWrapper />
    </Drawer>
  );
}
