import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, Stack, Typography } from '@mui/material';
//
import Item from './item';
import LoadingItems from 'src/components/loading-items';
import MapAsideWrapperEmptyDetail from '../../../empty-detail';
import MapAsideWrapperTaskAssignmentTeamsItemEmpty from './empty';
import useMapAsideTeamsStore, { getMapsAsideTeamsSearch } from 'src/store/map-aside-teams';

export default function MapAsideWrapperTaskAssignmentTeams() {
  const mapAsideTeamsStore = useMapAsideTeamsStore(useShallow((s) => s));

  if (mapAsideTeamsStore.loading) {
    return (
      <LoadingItems height={1} count={20} spacing={1.5}>
        <MapAsideWrapperTaskAssignmentTeamsItemEmpty />
      </LoadingItems>
    );
  }

  if (mapAsideTeamsStore.response.count === 0) {
    return <MapAsideWrapperEmptyDetail />;
  }

  const nextFetch = () => {
    if (mapAsideTeamsStore.paginationLoading) {
      return;
    }

    useMapAsideTeamsStore.setState({ paginationLoading: true });
    getMapsAsideTeamsSearch(mapAsideTeamsStore.search, true);
  };

  return (
    <InfiniteScroll
      next={nextFetch}
      dataLength={mapAsideTeamsStore.response.items.length}
      hasMore={mapAsideTeamsStore.response.totalPages > mapAsideTeamsStore.response.totalItems}
      loader={
        mapAsideTeamsStore.loading ? (
          <Stack height={20} overflow="hidden" alignItems="center">
            <CircularProgress size={20} />
          </Stack>
        ) : null
      }
      scrollableTarget="persons-teams-pools-scrollable-div"
      endMessage={
        <Typography variant="subtitle2" textAlign="center" color="text.disabled">
          Listenin sonuna geldiniz...
        </Typography>
      }
    >
      {mapAsideTeamsStore.response.items.map((person) => {
        const checked = mapAsideTeamsStore.selectedItems.some((item) => item.id === person.id);

        return <Item key={person.id} item={person} checked={checked} />;
      })}
    </InfiniteScroll>
  );
}
