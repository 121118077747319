import React from 'react';
import { Divider, Stack } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
//
import { MAP_ASIDE_WIDTH } from 'src/layouts/map';
import useMapPointsStore from 'src/store/map-points';
import useMapsLayoutStore from 'src/store/maps-layout';
import { MapsLayoutEnum } from 'src/store/maps-layout/types';
import useMapAsideTeamsStore from 'src/store/map-aside-teams';
import MapAsideWrapperTaskAssignmentPointsWrapper from './points';
import useMapAsidePersonsStore from 'src/store/map-aside-persons';
import AsideWrapperTabs, { AsideWrapperTabsItem } from '../../../aside-tabs';
import MapAsideWrapperTaskAssignmentPersonsTeamsWrapper from './persons-teams';

interface Props {}

export default function MapAsideWrapperTaskAssignment(props: Props) {
  const mapsLayout = useMapsLayoutStore(useShallow((s) => s));
  const mapPointsStore = useMapPointsStore(useShallow((s) => s));
  const mapAsidePersonsTotalItems = useMapAsidePersonsStore((s) => s.response.totalItems);
  const mapAsideTeamsTotalItems = useMapAsideTeamsStore((s) => s.response.totalItems);

  const tabs = [
    {
      id: '0',
      label: 'Kişiler ve Ekipler',
      count: mapAsidePersonsTotalItems + mapAsideTeamsTotalItems,
    },
    { id: '1', label: 'Noktalar', count: mapPointsStore.response.count },
  ];

  const [activeTab, setActiveTab] = React.useState<AsideWrapperTabsItem>(tabs[0]);

  const handleTabChange = (tab: AsideWrapperTabsItem) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    if (activeTab.id === '0') {
      return <MapAsideWrapperTaskAssignmentPersonsTeamsWrapper />;
    }

    if (activeTab.id === '1') {
      return <MapAsideWrapperTaskAssignmentPointsWrapper />;
    }
  };

  const renderLayout = () => {
    if (mapsLayout.activeType === MapsLayoutEnum.L) {
      return (
        <>
          <AsideWrapperTabs tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} />
          <Divider />
          {renderContent()}
        </>
      );
    }

    return <MapAsideWrapperTaskAssignmentPersonsTeamsWrapper />;
  };

  return (
    <Stack width={MAP_ASIDE_WIDTH} flexShrink={0} bgcolor="background.neutral">
      {renderLayout()}
    </Stack>
  );
}
