import React from 'react';
import { Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
//
import { NavDataItem, useNavData } from '../config-navigation';
import DashboardNavbarVerticalMenuParent from './menu-parent';
import { useActiveLink, getMenuActiveParentPath } from 'src/hooks/use-active-link';

interface Props {}

export default function LayoutDashboardNavbarVertical(props: Props) {
  const data = useNavData();
  const { pathname } = useLocation();
  const defaultActive = useActiveLink(pathname, true);
  const [openMenu, setOpenMenu] = React.useState<string | null>(null);

  const dataItems: NavDataItem[] = data.flatMap((item) => item.items);

  React.useEffect(() => {
    // Open parent menu if defaultActive is true
    if (defaultActive) {
      const lastPathParent = getMenuActiveParentPath(pathname, dataItems);
      if (lastPathParent) {
        setOpenMenu(lastPathParent);
      }
    }
  }, [dataItems, defaultActive, pathname]);

  return (
    <Stack flex={1} justifyContent="center" spacing={1.5}>
      {dataItems.map((item, indexSub) => (
        <DashboardNavbarVerticalMenuParent key={indexSub} item={item} visible={openMenu} />
      ))}
    </Stack>
  );
}
