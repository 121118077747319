import React from 'react';
import { ArrowLeft, ArrowRight } from 'iconsax-react';
import {
  Chip,
  Stack,
  Avatar,
  Divider,
  Tooltip,
  Collapse,
  useTheme,
  IconButton,
  Typography,
} from '@mui/material';
//
import { UsersType } from 'src/store/users/types';
import { StyledBadge } from 'src/layouts/dashboard/navbar/profile-box';

interface Props {
  users: UsersType[];
  visible: boolean;
}

export default function MapAsideWrapperTaskAssignmentTeamsItemMembers(props: Props) {
  const { visible, users } = props;
  const { palette } = useTheme();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [counter, setCounter] = React.useState(0);
  const intervalRef = React.useRef<any>(null);

  const startCounter = (direction: string) => {
    if (intervalRef.current) return;

    const hasLeft = direction === 'left';

    intervalRef.current = setInterval(() => {
      setCounter((prevCounter) => {
        const newValue = hasLeft ? prevCounter - 10 : prevCounter + 10;
        ref.current?.scrollTo({ left: newValue });
        return newValue;
      });
    }, 10);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const dataCount = users.length;

  const disabledLeftArrow = counter <= 0 || dataCount <= 2;
  const disabledRightArrow =
    counter >= (ref.current?.scrollWidth || 0) - ref.current?.clientWidth! || dataCount <= 2;

  const isDark = palette.mode === 'dark';

  return (
    <Collapse in={visible} unmountOnExit>
      <Stack spacing={1.25}>
        <Divider />
        {users.length === 0 ? (
          <Typography variant="caption" textAlign="center" width={1} pb={1.5}>
            Kullanıcı bulunamadı.
          </Typography>
        ) : (
          <>
            <Stack direction="row" spacing={0.75} alignItems="center" sx={{ px: 1.5 }}>
              <Stack flex={1} direction="row" spacing={0.75} alignItems="center">
                <Typography variant="subtitle2">Ekip Üyeleri</Typography>
                <Chip size="small" label={users.length} color="primary" variant="ghost" />
              </Stack>
              <Stack direction="row">
                <IconButton
                  size="small"
                  onMouseUp={stopCounter}
                  onMouseLeave={stopCounter}
                  disabled={disabledLeftArrow}
                  onMouseDown={() => startCounter('left')}
                >
                  <ArrowLeft size={16} variant="TwoTone" opacity={disabledLeftArrow ? 0.5 : 1} />
                </IconButton>
                <IconButton
                  size="small"
                  onMouseUp={stopCounter}
                  onMouseLeave={stopCounter}
                  disabled={disabledRightArrow}
                  onMouseDown={() => startCounter('right')}
                >
                  <ArrowRight size={16} variant="TwoTone" opacity={disabledRightArrow ? 0.5 : 1} />
                </IconButton>
              </Stack>
            </Stack>
            <Stack
              ref={ref}
              spacing={0.75}
              direction="row"
              overflow="auto"
              flexWrap="nowrap"
              onScroll={(e) => setCounter(e.currentTarget.scrollLeft)}
              sx={{
                px: 1.5,
                pb: 1.5,
                '&': { scrollbarWidth: 'none' },
                '&::-webkit-scrollbar': { display: 'none' },
              }}
            >
              {users.map((user, index) => (
                <Tooltip key={index} title={user.fullName}>
                  <Stack
                    sx={{
                      p: 1.25,
                      width: 103.5,
                      flexShrink: 0,
                      borderRadius: 1,
                      textAlign: 'center',
                      alignItems: 'center',
                      border: '1px solid',
                      flexDirection: 'column',
                      borderColor: 'background.neutral',
                      bgcolor: isDark ? 'background.neutral' : 'grey.100',
                    }}
                  >
                    <StyledBadge
                      variant="dot"
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                      <Avatar sx={{ width: 32, height: 32 }} src={user.photoUrl} />
                    </StyledBadge>
                    <Typography
                      pt={1}
                      pb={0.6}
                      width={1}
                      variant="caption"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      color="text.secondary"
                      textOverflow="ellipsis"
                    >
                      {user.fullName}
                    </Typography>
                    <Typography
                      width={1}
                      fontSize={12}
                      fontWeight="600"
                      variant="caption"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      {user.email}
                    </Typography>
                  </Stack>
                </Tooltip>
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Collapse>
  );
}
