// ----------------------------------------------------------------------

export default function Button(theme: any) {
  const isDark = theme.palette.mode === 'dark';

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedInherit: {
          color: !isDark ? theme.palette.grey[800] : theme.palette.grey[400],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: !isDark ? theme.palette.grey[400] : theme.palette.grey[700],
          },
        },
        containedPrimary: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary[isDark ? 'dark' : 'main'],
        },
        containedSecondary: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary[isDark ? 'dark' : 'main'],
        },
        containedInfo: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.info[isDark ? 'dark' : 'main'],
        },
        containedSuccess: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.success[isDark ? 'dark' : 'main'],
        },
        containedWarning: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.warning[isDark ? 'dark' : 'main'],
        },
        containedError: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.error[isDark ? 'dark' : 'main'],
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
