import React from 'react';
import { Stack, Box } from '@mui/material';

//
import MapAsideSubWrapperEmptyDetail from '../empty-detail';
import MapAsideSubWrapperPointDetailCollapses from './collapses';
import MapAsideSubWrapperMultipleProcessesSelectbox from '../multiple-processes-select-box';

interface Props {}

export default function MapAsideSubWrapperPointDetail(props: Props) {
  const hasList = true;

  if (!hasList) {
    return <MapAsideSubWrapperEmptyDetail />;
  }

  return (
    <Stack flex={1} overflow="hidden">
      <Box p={1.5}>
        <MapAsideSubWrapperMultipleProcessesSelectbox />
      </Box>
      <Stack
        id="points-detail-scrollable-div"
        sx={{
          flex: 1,
          p: 1.5,
          pt: 0,
          gap: 1.5,
          overflow: 'auto',
          '&': { scrollbarWidth: 'none' },
          '&::-webkit-scrollbar': { display: 'none' },
          '& .infinite-scroll-component': { gap: 1.25, display: 'flex', flexDirection: 'column' },
        }}
      >
        <MapAsideSubWrapperPointDetailCollapses />
      </Stack>
    </Stack>
  );
}
