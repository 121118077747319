import * as React from 'react';
import { Divider } from '@mui/material';
//
import CalendarBottomWrapper from 'src/components/calendar/bottom-wrapper';

interface Props {}

export default function LayoutCalendarBottom(props: Props) {
  return (
    <>
      <Divider />
      <CalendarBottomWrapper />
    </>
  );
}
