import React from 'react';
//
import Header from './header';
import TableContent from './table';
import { MapsBottomTasksToPlannedItem } from 'src/store/maps-bottom-tasks-to-planned/types';
import {
  Box,
  Table,
  Dialog,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from '@mui/material';
//
import ModalFooter from 'src/components/modals/footer';
import ModalHeader from 'src/components/modals/header';
import ModalContent from 'src/components/modals/content';

interface Props {
  tableHeight: number;
}

export default function MapBottomWrapperTasksToPlanned(props: Props) {
  const { tableHeight } = props;
  const [visibleItem, setVisibleItem] = React.useState<MapsBottomTasksToPlannedItem | null>(null);

  return (
    <>
      <Header />
      <TableContent tableHeight={tableHeight} setVisibleItem={setVisibleItem} />
      {visibleItem && (
        <Dialog open fullWidth maxWidth="md">
          <ModalContent>
            <ModalHeader title={visibleItem.name} description="Planlanmamış Noktalar" />
            <Box sx={{ maxHeight: 500, overflow: 'auto' }}>
              <TableContainer sx={{ borderRadius: 1 }}>
                <Table>
                  <TableHead sx={{ '& th': { bgcolor: 'background.neutral' } }}>
                    <TableRow>
                      <TableCell>Nokta ID</TableCell>
                      <TableCell>Nokta Adı</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleItem.points.map((point) => (
                      <TableRow key={point.id}>
                        <TableCell width={140}>{point.id}</TableCell>
                        <TableCell width={280}>{point.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </ModalContent>
          <ModalFooter onClose={() => setVisibleItem(null)} onCloseTitle="Kapat" />
        </Dialog>
      )}
    </>
  );
}
