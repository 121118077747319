import React from 'react';
import { CloseSquare } from 'iconsax-react';
import { IconButton, Stack, Typography } from '@mui/material';

interface Props {
  onClose(): void;
}

export default function CalendarFiltersHeader(props: Props) {
  const { onClose } = props;
  return (
    <Stack height={64} direction="row" alignItems="center" px={2.5}>
      <Typography variant="subtitle1" flex={1}>
        Fitrele
      </Typography>
      <IconButton onClick={onClose}>
        <CloseSquare size={20} variant="Bulk" />
      </IconButton>
    </Stack>
  );
}
