import * as React from 'react';
import { Stack, SxProps, Typography } from '@mui/material';

interface Props {
  title: string;
  children: React.ReactNode;
  sx?: SxProps;
  actionRight?: React.ReactNode;
}

export default function InputWrapper(props: Props) {
  const { title, children, sx, actionRight } = props;
  return (
    <Stack spacing={0.5} sx={sx}>
      <Stack direction="row" alignItems="center">
        <Typography flex={1} variant="subtitle2">
          {title}
        </Typography>
        {actionRight}
      </Stack>
      {children}
    </Stack>
  );
}
