import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import axios, { endpoints } from 'src/utils/axios';
import useMapAsideTeamsStore from '../map-aside-teams';
import useMapAsidePersonsStore from '../map-aside-persons';
import { MapAsidePoolsListItem, MapAsidePoolsResponse, MapAsidePoolsStore } from './types';

const initialState: MapAsidePoolsStore = {
  search: '',
  loading: true,
  selectedItems: [],
  searchLoading: false,
  paginationLoading: false,
  //
  response: {
    count: 0,
    items: [],
    totalPages: 0,
    totalItems: 0,
  },
};

const useMapAsidePoolsStore = create<MapAsidePoolsStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useMapAsidePoolsStore;

const { setState, getState } = useMapAsidePoolsStore;

export const getMapsAsidePoolsSearch = async (search: string, hasPagination?: boolean) => {
  try {
    const res = await axios.get<{ data: MapAsidePoolsResponse }>(
      endpoints.pools.list(20, 0, search)
    );
    setState({ response: res.data.data });
  } catch (error) {
    enqueueSnackbar('Havuzlar getirilirken bir hata oluştu.', { variant: 'error' });
  } finally {
    setState({ loading: false, searchLoading: false, paginationLoading: false });
  }
};

export const onChangeMapAsidePoolsChecked = (item: MapAsidePoolsListItem, checked: boolean) => {
  const state = getState();

  // Seçilen ekipleri temizle
  if (useMapAsideTeamsStore.getState().selectedItems.length > 0) {
    useMapAsideTeamsStore.setState({ selectedItems: [] });
    enqueueSnackbar('Havuzlar da işlem yaptığınız için seçilen Ekipler temizlendi.', {
      variant: 'default',
    });
  }

  // Seçilen kişileri temizle
  if (useMapAsidePersonsStore.getState().selectedItems.length > 0) {
    useMapAsidePersonsStore.setState({ selectedItems: [] });
    enqueueSnackbar('Havuzlar da işlem yaptığınız için seçilen Kişiler temizlendi.', {
      variant: 'default',
    });
  }

  const selectedItems = checked
    ? [...state.selectedItems, item]
    : state.selectedItems.filter((i) => i.id !== item.id);

  setState({ selectedItems });
};
