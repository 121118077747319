import { create } from 'zustand';
//
import axios, { endpoints } from 'src/utils/axios';
import { TeamsResponseType, TeamsStoreType } from './types';

const initialState: TeamsStoreType = {
  count: 0,
  items: [],
  error: false,
  loading: true,
};

const useTeamsStore = create<TeamsStoreType>(() => ({
  ...initialState,
}));

export default useTeamsStore;

const { setState } = useTeamsStore;

// REQUESTS
export const getAllTeams = async () => {
  setState(initialState);
  try {
    const response = await axios.get<{ data: TeamsResponseType }>(endpoints.teams.list());
    setState(response.data.data);
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};
