import { jwtDecode } from 'jwt-decode';

export default function ProfileMock() {
  const accessToken = localStorage.getItem('token') as string;
  const decoded: any = jwtDecode(accessToken);

  return {
    email: decoded?.email || '',
    fullName: decoded?.name || '',
  };
}
