import React from 'react';
import { Stack } from '@mui/material';
//
import { MAP_ASIDE_WIDTH } from '.';
import MapAsideWrapperTaskAssignmentPointsWrapper from 'src/components/map/aside-wrapper/task-assignment/points';

export default function LayoutMapAsideRight() {
  return (
    <Stack width={MAP_ASIDE_WIDTH} flexShrink={0} bgcolor="background.neutral">
      <MapAsideWrapperTaskAssignmentPointsWrapper />
    </Stack>
  );
}
