import { create } from 'zustand';
//
import { enqueueSnackbar } from 'notistack';
import { MapPointsMarkerStore } from './types';
import useMapActionsStore from '../map-actions';
import axios, { endpoints } from 'src/utils/axios';
import { MapPointSearchResponse } from '../map-points/types';

const initialState: MapPointsMarkerStore = {
  loading: true,
  response: {
    page: 0,
    data: [],
    count: 0,
    size: 100,
    totalPages: 0,
  },
};

const useMapPointsMarkerStore = create<MapPointsMarkerStore & { reset(): void }>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));

export default useMapPointsMarkerStore;

const { setState } = useMapPointsMarkerStore;

export const getSearchMapPointMarkers = async (
  index: string,
  search: string,
  json: string,
  size: number
) => {
  setState({ loading: true });
  useMapActionsStore.setState({ selectedsPoints: [] });

  try {
    const res = await axios.post<MapPointSearchResponse>(
      endpoints.geomarketingSearchPoints(1, size),
      {
        json,
        index,
        term: `*${search}*`,
      }
    );
    setState({ response: res.data });
  } catch (error) {
    enqueueSnackbar('Nokta getirme işlemi sırasında bir hata oluştu.', { variant: 'error' });
  } finally {
    setState({ loading: false });
  }
};
