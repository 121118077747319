import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
//
import CalendarBottomHeader from './header';
import CalendarBottomUnasignedTasks from './unasigned-tasks';
import useCalendarLayoutStore from 'src/store/calendar-layout';

const CalendarBottomWrapperHeights = {
  small: 60,
  large: 200,
};

export default function CalendarBottomWrapper() {
  const calendarLayoutStore = useCalendarLayoutStore(useShallow((s) => s));

  return (
    <Stack
      spacing={1.5}
      sx={{
        p: 1.5,
        flexShrink: 0,
        overflow: 'hidden',
        bgcolor: 'background.default',
        pb: calendarLayoutStore.bottomVisible ? 0 : 1.5,
        height: calendarLayoutStore.bottomVisible
          ? CalendarBottomWrapperHeights.large
          : CalendarBottomWrapperHeights.small,
      }}
    >
      <CalendarBottomHeader
        visible={calendarLayoutStore.bottomVisible}
        toggleVisible={() => {
          useCalendarLayoutStore.setState({
            bottomVisible: !calendarLayoutStore.bottomVisible,
          });
        }}
      />
      <Box
        sx={{
          p: 1.5,
          flex: 1,
          flexShrink: 0,
          overflow: 'auto',
          bgcolor: 'background.neutral',
          '&': { scrollbarWidth: 'none' },
          '&::-webkit-scrollbar': { display: 'none' },
          borderRadius: (t) => `${t.spacing(1)} ${t.spacing(1)} 0 0`,
          display: calendarLayoutStore.bottomVisible ? 'block' : 'none',
        }}
      >
        <CalendarBottomUnasignedTasks />
      </Box>
    </Stack>
  );
}
