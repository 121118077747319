import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
//
import axios, { endpoints } from 'src/utils/axios';
import {
  TaskDefinitionListType,
  TaskDefinitionListStoreType,
  TaskDefinitionListUpdateType,
  TaskDefinitionListStatusEnum,
  TaskDefinitionListResponseType,
  RequestGetAllTaskDefinitionsType,
} from './types';

const initialState: TaskDefinitionListStoreType = {
  list: [],
  error: false,
  totalPages: 0,
  loading: true,
  totalElements: 0,
  // Pagination custom
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
};

const useTaskDefinitionListStore = create<TaskDefinitionListStoreType>(() => ({
  ...initialState,
}));

export default useTaskDefinitionListStore;

const { setState, getState } = useTaskDefinitionListStore;

// REQUESTS
export const getAllTaskDefinitions = async (body?: RequestGetAllTaskDefinitionsType) => {
  setState(initialState);

  try {
    const response = await axios.post<TaskDefinitionListResponseType>(endpoints.taskTemplate.list, {
      ...(body || {}),
      ascending: false,
      sortBy: 'id',
    });
    setState(response.data);
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};

export const updateTaskDefinition = async (body: TaskDefinitionListUpdateType) => {
  const { id, ...sendData } = body;

  try {
    const res = await axios.put(endpoints.taskTemplate.update(body.id), {
      ...sendData,
    });
    setState((state) => ({
      list: state.list.map((item) => {
        if (item.id === body.id) {
          return res.data;
        }
        return item;
      }),
    }));
    enqueueSnackbar('güncellendi.', { variant: 'success' });
  } catch (error) {
    enqueueSnackbar('Güncellenirken bir hata oluştu.', { variant: 'error' });
  }
};

export const updateTaskStatus = async (
  id: TaskDefinitionListType['id'],
  status: TaskDefinitionListStatusEnum
) => {
  try {
    await axios.patch(endpoints.taskTemplate.updateStatus(id), {
      status,
    });
    setState((state) => ({
      list: state.list.map((item) => {
        if (item.id === id) {
          return { ...item, status };
        }
        return item;
      }),
    }));
    enqueueSnackbar('Durumu güncellendi.', { variant: 'success' });
  } catch (error) {
    enqueueSnackbar('Durum güncellenirken bir hata oluştu.', { variant: 'error' });
  } finally {
  }
};

export const onPaginationChange = async (updater: any) => {
  const oldPagination = getState().pagination;

  const newPagination = updater(oldPagination);

  await getAllTaskDefinitions({ page: newPagination.pageIndex + 1, size: newPagination.pageSize });

  setState({ pagination: newPagination });
};

export const getTaskDefinition = async (id: TaskDefinitionListType['id']) => {
  try {
    const res = await axios.get<TaskDefinitionListType>(endpoints.taskTemplate.get(id));
    return res.data;
  } catch (error) {
    throw error;
  }
};
