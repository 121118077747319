import React from 'react';
import { Checkbox, Stack, Typography } from '@mui/material';

interface Props {
  name: string;
  onChange(): void;
  checked: boolean;
}

export default React.forwardRef(function CalendarFiltersContentOptionsItem(props: Props, ref: any) {
  const { name, onChange, checked } = props;
  return (
    <Stack ref={ref} direction="row" alignItems="center">
      <Checkbox size="small" checked={checked} onChange={onChange} />
      <Typography variant="subtitle2">{name}</Typography>
    </Stack>
  );
});
