import { create } from 'zustand';
//
import { getCalendarData } from '../calendar';
import axios, { endpoints } from 'src/utils/axios';
import { UsersResponseType } from '../users/types';
import { TeamsResponseType } from '../teams/types';
import useCalendarLayoutStore from '../calendar-layout';
import { setCalendarAsideData } from '../calendar-aside';
import { GetAllMapsBottomTasks } from '../maps-bottom-tasks/types';
import { CalendarAsideResponse } from '../calendar-aside/types';
import {
  MapsBottomTasksToPlannedResponse,
  type MapsBottomTasksToPlannedStore,
  type MapsBottomTasksToPlannedStoreActions,
} from './types';

const initialState: MapsBottomTasksToPlannedStore = {
  // Response
  response: {
    list: [],
    totalPages: 0,
    totalElements: 0,
  },
  //
  search: '',
  error: false,
  loading: false,
  rowSelection: {},
  rowSelectionCount: 0,
  pagination: { pageIndex: 0, pageSize: 20 },
  // Actions
  handlePlannedLoading: false,
};

const useMapsBottomTasksToPlannedStore = create<
  MapsBottomTasksToPlannedStore & MapsBottomTasksToPlannedStoreActions
>((set) => ({
  ...initialState,
  reset: () => set(initialState),
  onRowSelectionChange: (updater: any) => {
    if (typeof updater === 'function') {
      return set((s) => {
        const oldRowSelection = s.rowSelection;
        const rowSelection = updater(oldRowSelection);
        const rowSelectionCount = Object.keys(rowSelection).length;
        return { rowSelection, rowSelectionCount };
      });
    }

    const rowSelectionCount = Object.keys(updater).length;

    return set({ rowSelection: updater, rowSelectionCount });
  },
}));

export default useMapsBottomTasksToPlannedStore;

const { setState, getState } = useMapsBottomTasksToPlannedStore;

export const getMapsBottomAllTasksToPlanned = async (
  body?: GetAllMapsBottomTasks,
  pagination?: boolean
) => {
  setState({ loading: true });
  const state = getState();

  const newPageIndex = pagination ? state.pagination.pageIndex + 1 : 1;

  setState({ pagination: { ...state.pagination, pageIndex: newPageIndex } });

  const payload: GetAllMapsBottomTasks = {
    page: newPageIndex,
    size: initialState.pagination.pageSize,
    ...body,
  };

  try {
    const response = await axios.post<MapsBottomTasksToPlannedResponse>(
      endpoints.task.willBePlannedList,
      payload
    );

    setState((s) => ({
      response: {
        ...response.data,
        list: pagination ? [...s.response.list, ...response.data.list] : response.data.list,
        totalElements: body?.searchKey ? s.response.totalElements : response.data.totalElements,
      },
    }));
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};

export const handleManuelPlan = async (taskIds: string[]) => {
  setState({ handlePlannedLoading: true });

  try {
    const res = await axios.post<CalendarAsideResponse>(endpoints.task.manuelPlan, {
      taskIds,
    });

    setCalendarAsideData(res.data);

    // Get selecteds persons and teams (Pools will be added)
    const resPersons = await axios.post<{ data: UsersResponseType }>(endpoints.users.findList, {
      employeeIds: res.data.users.map((user) => user.id),
    });
    const resTeams = await axios.post<{ data: TeamsResponseType }>(endpoints.teams.findList, {
      teamIds: res.data.teams.map((team) => team.id),
    });

    useCalendarLayoutStore.setState((s) => ({
      filters: {
        ...s.filters,
        selectedsTeams: resTeams.data.data.items,
        selectedsPersons: resPersons.data.data.items,
      },
    }));

    // Fetch calendar left and right data
    getCalendarData();
  } catch (error) {
    throw error;
  } finally {
    setState({ handlePlannedLoading: false });
  }
};

export const handleAutoPlan = async (taskIds: string[]) => {};
