import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { Box, Divider, Stack } from '@mui/material';
//
import MapAsideWrapperSearch from '../../search';
import MapAsideWrapperTaskAssignmentPools from './pools';
import MapAsideWrapperTaskAssignmentTeams from './teams';
import MapAsideWrapperTaskAssignmentPersons from './persons';
import { AsideWrapperTabsItem } from '../../../../aside-tabs';
import AsideWrapperTabsCustom from '../../../../aside-tabs-custom';
import useMapAsideTeamsStore, { getMapsAsideTeamsSearch } from 'src/store/map-aside-teams';
import useMapAsidePoolsStore, { getMapsAsidePoolsSearch } from 'src/store/map-aside-pools';
import useMapAsidePersonsStore, { getMapsAsidePersonsSearch } from 'src/store/map-aside-persons';

type Option = {
  placeholder: string;
  search: string;
  searchCount: number;
  searchLoading: boolean;
};

export default function MapAsideWrapperTaskAssignmentPersonsTeamsWrapper() {
  const searchRef = React.useRef<any>(null);
  const mapAsidePersonsStore = useMapAsidePersonsStore(useShallow((s) => s));
  const mapAsideTeamsStore = useMapAsideTeamsStore(useShallow((s) => s));
  const mapAsidePoolsStore = useMapAsidePoolsStore(useShallow((s) => s));

  const tabs = [
    { id: 'kisiler', label: 'Kişiler', count: mapAsidePersonsStore.response.totalItems },
    { id: 'ekipler', label: 'Ekipler', count: mapAsideTeamsStore.response.totalItems },
    { id: 'havuz', label: 'Havuz', count: mapAsidePoolsStore.response.totalItems },
  ];
  const [activeTab, setActiveTab] = React.useState<AsideWrapperTabsItem>(tabs[0]);

  const isKisiler = activeTab.id === 'kisiler';
  const isEkipler = activeTab.id === 'ekipler';
  const isHavuz = activeTab.id === 'havuz';

  const options: Record<string, Option> = {
    kisiler: {
      placeholder: 'Kişi Ara',
      search: mapAsidePersonsStore.search,
      searchCount: mapAsidePersonsStore.response.items.length,
      searchLoading: mapAsidePersonsStore.searchLoading,
    },
    ekipler: {
      placeholder: 'Ekip Ara',
      search: mapAsideTeamsStore.search,
      searchCount: mapAsideTeamsStore.response.items.length,
      searchLoading: mapAsideTeamsStore.searchLoading,
    },
    havuz: {
      placeholder: 'Havuz Ara',
      search: mapAsidePoolsStore.search,
      searchCount: mapAsidePoolsStore.response.items.length,
      searchLoading: mapAsidePoolsStore.searchLoading,
    },
  };

  const optionsActive = options[activeTab.id];

  const handleTabChange = (tab: AsideWrapperTabsItem) => {
    setActiveTab(tab);
  };

  const onChangeSearch = (search: string) => {
    if (isKisiler) {
      useMapAsidePersonsStore.setState({ search, searchLoading: true });
    } else if (isEkipler) {
      useMapAsideTeamsStore.setState({ search, searchLoading: true });
    } else if (isHavuz) {
      useMapAsidePoolsStore.setState({ search, searchLoading: true });
    }

    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    searchRef.current = setTimeout(() => {
      if (isKisiler) {
        getMapsAsidePersonsSearch(search);
      } else if (isEkipler) {
        getMapsAsideTeamsSearch(search);
      } else if (isHavuz) {
        getMapsAsidePoolsSearch(search);
      }
    }, 1000);
  };

  const renderContent = () => {
    if (isKisiler) {
      return <MapAsideWrapperTaskAssignmentPersons />;
    }

    if (isEkipler) {
      return <MapAsideWrapperTaskAssignmentTeams />;
    }

    if (isHavuz) {
      return <MapAsideWrapperTaskAssignmentPools />;
    }

    return null;
  };

  return (
    <Stack flex={1} overflow="hidden">
      <MapAsideWrapperSearch
        setSearch={onChangeSearch}
        search={optionsActive.search}
        count={optionsActive.searchCount}
        loading={optionsActive.searchLoading}
        placeholder={optionsActive.placeholder}
      />
      <Divider />
      <Box px={0.75}>
        <AsideWrapperTabsCustom
          tabs={tabs}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
      </Box>
      <Divider />
      <Box
        id="persons-teams-pools-scrollable-div"
        sx={{
          p: 1.5,
          flex: 1,
          overflow: 'auto',
          '&': { scrollbarWidth: 'none' },
          '&::-webkit-scrollbar': { display: 'none' },
          '& .infinite-scroll-component': { display: 'flex', flexDirection: 'column', gap: 1.25 },
        }}
      >
        {renderContent()}
      </Box>
    </Stack>
  );
}
