import React from 'react';
import { DndProvider } from 'react-dnd';
import { Outlet } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { Box, Divider, Stack } from '@mui/material';
import { HTML5Backend } from 'react-dnd-html5-backend';
//
import LayoutMapAside from './aside';
import LayoutMapNavbar from './navbar';
import LayoutMapBottom from './bottom';
import Loading from 'src/components/loading';
import LayoutMapAsideSub from './aside-sub';
import LayoutMapAsideRight from './aside-right';
import LayoutMapCalendarHeader from '../header';
import { layoutMapConfig } from 'src/config-global';
import useMapsLayoutStore from 'src/store/maps-layout';
import useMapActionsStore from 'src/store/map-actions';
import { MapsLayoutEnum } from 'src/store/maps-layout/types';
import { getBaseWorkspace } from 'src/store/base-workspaces';
import CustomDragLayer from 'src/components/custom-drag-layer';
import useMapAsideSubPersonsStore from 'src/store/map-aside-sub-persons';

export const MAP_ASIDE_WIDTH = 360;

export default function LayoutMap() {
  const mapsLayout = useMapsLayoutStore(useShallow((s) => s));

  React.useEffect(() => {
    getBaseWorkspace(true);

    return () => {
      // Burada map resetleniyor.
      useMapActionsStore.getState().reset();
      useMapsLayoutStore.getState().reset();
      useMapAsideSubPersonsStore.getState().reset();
    };
  }, []);

  const visibleRightAside = mapsLayout.activeType === MapsLayoutEnum.U;
  const contentHeight = `calc(100dvh - ${
    layoutMapConfig.headerSize + layoutMapConfig.navbarSize
  }px)`;

  return (
    <Box height="100dvh" overflow="hidden" position="relative">
      <React.Suspense fallback={<Loading />}>
        <DndProvider backend={HTML5Backend}>
          <CustomDragLayer />
          <LayoutMapCalendarHeader />
          <LayoutMapNavbar />
          <Stack direction="row" height={contentHeight}>
            <LayoutMapAside />
            <Divider orientation="vertical" />
            {mapsLayout.asideSubVisible && <LayoutMapAsideSub />}
            <Divider orientation="vertical" />
            <Stack flex={1} component="main" overflow="hidden">
              {/* Map Screen sayfasına ne eklenirse o geliyor buraya. (Outlet) */}
              <Outlet />
              <LayoutMapBottom />
            </Stack>
            {visibleRightAside && <LayoutMapAsideRight />}
          </Stack>
        </DndProvider>
      </React.Suspense>
    </Box>
  );
}
