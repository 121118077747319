import React from 'react';
import { Box, Stack } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import 'react-resizable/css/styles.css';
//
import { layoutMapConfig } from 'src/config-global';
import useMapsLayoutStore from 'src/store/maps-layout';
import useMapActionsStore from 'src/store/map-actions';
import { MapsLayoutBottomTabsEnum } from 'src/store/maps-layout/types';
import {
  MapBottomWrapperTasks,
  MapBottomWrapperTasksToPlanned,
} from 'src/components/map/bottom-wrapper';

const minHeight = 300;
const tableHeaderHeight = 96;
const totalHeadersHeight = layoutMapConfig.headerSize + layoutMapConfig.navbarSize;

export default function LayoutMapBottom() {
  const mapsLayoutStore = useMapsLayoutStore(useShallow((s) => s));
  const mapActionsStore = useMapActionsStore(useShallow((s) => s));
  const contentHeight = (window.innerHeight - totalHeadersHeight) / 2.5;
  const [scrollHeight, setScrollHeight] = React.useState(contentHeight);
  const [wrapperHeight, setWrapperHeight] = React.useState(contentHeight);

  const tableHeight = wrapperHeight - tableHeaderHeight;

  const renderContent = () => {
    if (mapsLayoutStore.bottomActiveTab === MapsLayoutBottomTabsEnum.TASKS) {
      return <MapBottomWrapperTasks tableHeight={tableHeight} />;
    }

    return <MapBottomWrapperTasksToPlanned tableHeight={tableHeight} />;
  };

  const handleResize = React.useCallback(
    (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
      setScrollHeight(size.height);
    },
    []
  );

  const handleResizeStop = React.useCallback(
    (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
      setWrapperHeight(size.height);
    },
    []
  );

  const newMinHeight = mapActionsStore.fullScreen ? 0 : minHeight;
  const newScrollHeight = mapActionsStore.fullScreen ? 0 : scrollHeight;

  return (
    <Resizable
      width={Infinity}
      resizeHandles={['n']}
      onResize={handleResize}
      height={newScrollHeight}
      onResizeStop={handleResizeStop}
      minConstraints={[Infinity, newMinHeight]}
      maxConstraints={[Infinity, window.innerHeight - totalHeadersHeight]}
      handle={(_, ref) => {
        return (
          <Stack
            ref={ref}
            className="react-resizable-handle"
            style={{
              top: 5,
              height: 8,
              width: '100%',
              cursor: 'ns-resize',
              alignItems: 'center',
              placeContent: 'center',
            }}
          >
            <Box sx={{ height: 8, width: 200, borderRadius: 1, bgcolor: 'background.neutral' }} />
          </Stack>
        );
      }}
    >
      <Box
        sx={{
          flexShrink: 0,
          overflowY: 'hidden',
          minHeight: newMinHeight,
          height: newScrollHeight,
        }}
      >
        {renderContent()}
      </Box>
    </Resizable>
  );
}
