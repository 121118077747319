import React from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { Box, Stack, Typography } from '@mui/material';
//
import { DraggablesTypes } from 'src/utils/draggables';

function snapToGrid(x: number, y: number): [number, number] {
  const snappedX = Math.round(x / 32) * 32;
  const snappedY = Math.round(y / 32) * 32;
  return [snappedX, snappedY];
}

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  isSnapToGrid: boolean
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  let { x, y } = currentOffset;

  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    [x, y] = snapToGrid(x, y);
    x += initialOffset.x;
    y += initialOffset.y;
  }

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export default function CustomDragLayer() {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
    itemType: monitor.getItemType() as DraggablesTypes,
    initialOffset: monitor.getInitialSourceClientOffset(),
  }));

  const renderItem = () => {
    if (!itemType) return null;

    if (itemType === DraggablesTypes.TABLE_TASK) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            py: 1,
            px: 2,
            borderRadius: 1,
            color: 'common.white',
            display: 'inline-block',
            bgcolor: 'primary.main',
          }}
        >
          <Typography variant="subtitle2">{item.length}</Typography>
        </Stack>
      );
    }

    if (itemType === DraggablesTypes.TASK_TYPE) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            px: 2,
            height: 48,
            width: 240,
            borderRadius: 1,
            bgcolor: 'primary.main',
          }}
        >
          <Typography variant="subtitle2" textAlign="center" color="common.white">
            {item.name}
          </Typography>
        </Stack>
      );
    }
  };

  if (!isDragging) {
    return null;
  }

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        zIndex: 100,
        position: 'fixed',
        pointerEvents: 'none',
      }}
    >
      <Box style={getItemStyles(initialOffset, currentOffset, false)}>{renderItem()}</Box>
    </Box>
  );
}
