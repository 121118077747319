import { create } from 'zustand';
//
import axios, { endpoints } from 'src/utils/axios';
import { AvailableFunction, AvailableFunctionsStore } from './types';

const initialState: AvailableFunctionsStore = {
  list: [],
  error: false,
  loading: true,
};

const useAvailableFunctionsStore = create<AvailableFunctionsStore>(() => ({
  ...initialState,
}));

export default useAvailableFunctionsStore;

const { setState } = useAvailableFunctionsStore;

// REQUESTS
export const getAllAvailableFunctions = async () => {
  setState(initialState);

  try {
    const response = await axios.get<AvailableFunction[]>(endpoints.availableFunctions.list);
    setState({ list: response.data });
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};
