import { useMemo } from 'react';
import { Element4, DocumentText, Data, Setting } from 'iconsax-react';
//
import { paths } from 'src/routes/paths';

export type NavDataItem = {
  title: string;
  path: string;
  icon?: JSX.Element;
  children?: NavDataItem[];
};

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: 'gösterge paneli',
            path: paths.dashboard.root,
            icon: <Element4 variant="Bulk" size={20} />,
          },
        ],
      },

      // TASKS
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: 'task işlemleri',
            path: paths.tasks.definition,
            icon: <DocumentText variant="Bulk" size={20} />,
            children: [
              { title: 'task tipi tanımla', path: paths.tasks.definition },
              { title: 'task oluştur', path: paths.tasks.root },
            ],
          },
        ],
      },
      // PLANNING
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: 'planlama',
            path: paths.map,
            icon: <Data variant="Bulk" size={20} />,
          },
        ],
      },
      // SETTINGS
      // ----------------------------------------------------------------------
      {
        items: [
          {
            title: 'ayarlar',
            path: paths.settings.reasonsRejection,
            icon: <Setting variant="Bulk" size={20} />,
            children: [
              { title: 'red nedenleri', path: paths.settings.reasonsRejection },
              { title: 'task - neden eşleştir', path: paths.settings.rejection },
              { title: 'uygulama ayarları', path: paths.settings.applicationSettings },
            ],
          },
        ],
      },
    ],
    []
  );

  return data;
}
