import React from 'react';
import { CircularProgress, Divider, Stack } from '@mui/material';
//
import useMapsLayoutStore from 'src/store/maps-layout';
import useMapAsideSubPersonsStore from 'src/store/map-aside-sub-persons';
import MapAsideSubWrapperHeader from 'src/components/map/aside-sub-wrapper/header';
import {
  MapAsideSubWrapperPointDetail,
  MapAsideSubWrapperPersonTeamDetail,
} from 'src/components/map/aside-sub-wrapper';

export default function LayoutMapAsideSub() {
  const asideSubVisible = useMapsLayoutStore((s) => s.asideSubVisible);
  const { loading, personDetail } = useMapAsideSubPersonsStore((s) => ({
    loading: s.loading,
    personDetail: s.personDetail,
  }));

  const renderContent = () => {
    if (loading) {
      return (
        <Stack flex={1} alignItems="center" justifyContent="center">
          <CircularProgress size={20} />
        </Stack>
      );
    }

    // Kişi ve Takım Detayı
    if (asideSubVisible === 'person-team') {
      return <MapAsideSubWrapperPersonTeamDetail />;
    }

    // Nokta Detayı
    return <MapAsideSubWrapperPointDetail />;
  };

  return (
    <Stack width={280} flexShrink={0} bgcolor="background.neutral" overflow="hidden">
      <MapAsideSubWrapperHeader
        itemPersonTeam={{ name: personDetail?.fullName }}
        itemPoint={asideSubVisible === 'point' ? { name: 'P&G Anadolu', markerColor: 'red' } : null}
      />
      <Divider />
      {renderContent()}
    </Stack>
  );
}
