import React from 'react';
import { Map } from 'iconsax-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Outlet, useNavigate } from 'react-router-dom';
import { Stack, Box, Divider, Button } from '@mui/material';
//
import { paths } from 'src/routes/paths';
import LayoutCalendarAside from './aside';
import LayoutCalendarBottom from './bottom';
import Loading from 'src/components/loading';
import LayoutCalendarOptions from './options';
import LayoutCalendarFilters from './filters';
import LayoutMapCalendarHeader from '../header';
import useCalendarStore from 'src/store/calendar';
import { layoutMapConfig } from 'src/config-global';
import useCalendarAsideStore from 'src/store/calendar-aside';
import useCalendarLayoutStore from 'src/store/calendar-layout';

export default function LayoutCalendar() {
  const navigate = useNavigate();
  const [windowHeight, setWindowHeight] = React.useState<number>(window.innerHeight);

  React.useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const contentHeight = windowHeight - layoutMapConfig.headerSize;

  React.useEffect(() => {
    return () => {
      // Burada calendar resetleniyor.
      useCalendarStore.getState().reset();
      useCalendarAsideStore.getState().reset();
      useCalendarLayoutStore.getState().reset();
    };
  }, []);

  return (
    <Box height="100dvh" overflow={{ xs: 'auto', md: 'hidden' }} position="relative">
      <React.Suspense fallback={<Loading />}>
        <DndProvider backend={HTML5Backend}>
          <>
            <LayoutMapCalendarHeader
              actionBtn={
                <Button startIcon={<Map variant="Bulk" />} onClick={() => navigate(paths.map)}>
                  Planlama
                </Button>
              }
            />
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              height={{ xs: 'auto', md: contentHeight }}
            >
              <LayoutCalendarAside />
              <Divider orientation="vertical" />
              <Stack flex={1} component="main" overflow="hidden">
                {/* Calendar Screen sayfasına ne eklenirse o geliyor buraya. (Outlet) */}
                <LayoutCalendarOptions />
                <Divider />
                <Outlet />
                <LayoutCalendarBottom />
              </Stack>
            </Stack>
            <LayoutCalendarFilters />
          </>
        </DndProvider>
      </React.Suspense>
    </Box>
  );
}
