import * as React from 'react';
import { Stack, StackProps } from '@mui/material';

interface Props extends StackProps {
  count: number;
}

export default function LoadingItems(props: React.PropsWithChildren<Props>) {
  const { count, ...other } = props;

  return <Stack {...other}>{[...new Array(count)].map(() => props.children)}</Stack>;
}
