import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { ControlledMenu, MenuItem } from '@szhsin/react-menu';
import { MapAsideSubWrapperMultipleProcessesSelectboxOptions } from '../multiple-processes-select-box';

interface Props {
  isOpen: boolean;
  handleClose(): void;
  anchorRef: React.RefObject<HTMLButtonElement>;
}

export default function MapAsideSubWrapperPersonTeamDetailCollapseItemActions(props: Props) {
  const { anchorRef, isOpen, handleClose } = props;

  const { palette } = useTheme();

  return (
    <ControlledMenu
      shift={3.5}
      align="end"
      direction="bottom"
      onClose={handleClose}
      anchorRef={anchorRef}
      theming={palette.mode}
      state={isOpen ? 'open' : 'closed'}
      menuStyle={{
        padding: 0,
        minWidth: 256,
        overflow: 'hidden',
        transform: 'translateY(7px)',
        border: `1px solid ${palette.primary.lighter}`,
      }}
    >
      {MapAsideSubWrapperMultipleProcessesSelectboxOptions.map((option) => (
        <MenuItem key={option.id} style={{ padding: '6px 16px', gap: 12 }}>
          {option.icon}
          <Typography variant="subtitle2">{option.title}</Typography>
        </MenuItem>
      ))}
    </ControlledMenu>
  );
}
