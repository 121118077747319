import React from 'react';
import { useDrag } from 'react-dnd';
import { Calendar } from 'iconsax-react';
import { ControlCamera } from '@mui/icons-material';
import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
//
import { DraggablesTypes } from 'src/utils/draggables';
import { CalendarAsideResponseTaskMap } from 'src/store/calendar-aside/types';

type Props = {
  task: CalendarAsideResponseTaskMap;
};

export default function CalendarAsideWrapperListItemPointsTasksItem(props: Props) {
  const { task } = props;

  const id = task.id.toString();

  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: DraggablesTypes.CALENDAR_ITEM,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.8 : 1,
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    item: task,
  }));

  return (
    <Stack
      spacing={1}
      ref={preview}
      direction="row"
      alignItems="center"
      sx={{
        pl: 1,
        opacity,
        borderRadius: 1,
        transition: '.4s ease',
        '&:hover': { bgcolor: (t) => t.palette.background.neutral },
      }}
    >
      <Typography
        flex={1}
        overflow="hidden"
        variant="subtitle2"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        <b>#{task.id}</b> {task.name}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Tooltip title={`Frekansa Uygun: ${task.count}`}>
          <Chip label={task.count} size="small" />
        </Tooltip>
        <Tooltip title="Takvime Atanmış: 3">
          <Chip
            label={3}
            size="small"
            color="primary"
            variant="ghost"
            icon={<Calendar variant="Bulk" size={16} />}
          />
        </Tooltip>
        <IconButton
          id={id}
          ref={drag}
          size="small"
          disableRipple
          sx={{ cursor: 'move' }}
          onDragStart={(e) => e.dataTransfer.setData('text/plain', id)}
        >
          <ControlCamera fontSize="small" color="disabled" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
