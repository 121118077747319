import React from 'react';
import { ArrowUp2, ArrowDown2 } from 'iconsax-react';
import { Collapse, Link, Stack, Tooltip, Typography } from '@mui/material';
//

import { NavDataItem } from '../config-navigation';
import { RouterLink } from 'src/routes/components';
import capitalizeWords from 'src/utils/capitalize-words';
import { useActiveLink } from 'src/hooks/use-active-link';
import DashboardNavbarVerticalMenuChildren from './menu-children';

interface Props {
  item: NavDataItem;
  visible?: string | null;
}

export default function LayoutDashboardNavbarVerticalMenuParent(props: Props) {
  const { item } = props;

  const hasVisible = props.visible === item.path;
  const active = useActiveLink(item.path, !!item.children);

  return (
    <Stack>
      <Link
        color="inherit"
        href={item.path}
        component={RouterLink}
        sx={{ textDecoration: 'none!important' }}
      >
        <Stack
          spacing={1.25}
          direction="row"
          alignItems="center"
          sx={{
            px: 1.5,
            height: 40,
            borderRadius: 1,
            cursor: 'pointer',
            bgcolor: hasVisible || active ? 'primary.main' : 'transparent',
            color: hasVisible || active ? 'primary.contrastText' : 'inherit',
            '&:hover': !hasVisible && !active ? { bgcolor: 'background.neutral' } : {},
          }}
        >
          <Stack flex={1} direction="row" alignItems="center" spacing={1.25}>
            <Stack sx={{ opacity: hasVisible ? 1 : 0.5 }}>{item.icon}</Stack>
            <Tooltip title={capitalizeWords(item.title)} placement="right">
              <Typography variant="subtitle2" textTransform="capitalize">
                {item.title}
              </Typography>
            </Tooltip>
          </Stack>

          {item.children && <>{hasVisible ? <ArrowUp2 size={20} /> : <ArrowDown2 size={20} />}</>}
        </Stack>
      </Link>
      {!!item.children && (
        <Collapse in={hasVisible} unmountOnExit>
          <DashboardNavbarVerticalMenuChildren items={item.children || []} />
        </Collapse>
      )}
    </Stack>
  );
}
