import { useDrag } from 'react-dnd';
import { ControlCamera } from '@mui/icons-material';
import { Calendar, TickCircle } from 'iconsax-react';
import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
//
import { DraggablesTypes } from 'src/utils/draggables';
import { remainingTaskCount } from 'src/store/calendar';
import {
  CalendarAsideResponseTaskMap,
  CalendarAsideResponseItemPoints,
} from 'src/store/calendar-aside/types';

type Props = {
  task: CalendarAsideResponseTaskMap;
  point: CalendarAsideResponseItemPoints;
};

export default function CalendarAsideWrapperListItemPointsTasksItem(props: Props) {
  const { task, point } = props;

  const id = task.id.toString();

  const remainingCount = remainingTaskCount(
    `${task.id}-${point.id}`,
    task.count - point.createdTaskCount
  );

  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: DraggablesTypes.CALENDAR_ITEM,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.8 : 1,
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    item: { task, point },
  }));

  return (
    <Stack
      ref={preview}
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{
        pl: 1,
        opacity,
        borderRadius: 1,
        transition: '.4s ease',
        '&:hover': { bgcolor: (t) => t.palette.background.neutral },
      }}
    >
      <Typography
        flex={1}
        overflow="hidden"
        variant="subtitle2"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        <b>#{task.id}</b> {task.name}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Tooltip title={`Frekansa Uygun: ${task.count}`}>
          <Chip label={task.count} size="small" />
        </Tooltip>
        {remainingCount > 0 ? (
          <>
            <Tooltip title={`Kalan Atama Sayısı: ${remainingCount}`}>
              <Chip
                size="small"
                color="primary"
                variant="ghost"
                label={remainingCount}
                icon={<Calendar variant="Bulk" size={16} />}
              />
            </Tooltip>
            <IconButton
              id={id}
              ref={drag}
              size="small"
              disableRipple
              sx={{ cursor: 'move' }}
              onDragStart={(e) => e.dataTransfer.setData('text/plain', id)}
            >
              <ControlCamera fontSize="small" color="disabled" />
            </IconButton>
          </>
        ) : (
          <IconButton size="small" disabled sx={{ '& svg': { color: 'success.main' } }}>
            <TickCircle variant="Bulk" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}
