import React from 'react';
import { GlobalSearch } from 'iconsax-react';
import { CircularProgress, InputAdornment, TextField, TextFieldProps } from '@mui/material';

type Props = TextFieldProps & {
  search: string;
  loading?: boolean;
  setSearch: (value: string) => void;
};

export default function SearchBox(props: Props) {
  const { search, setSearch, loading, ...other } = props;

  return (
    <TextField
      size="small"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <GlobalSearch size={20} variant="Bulk" />
          </InputAdornment>
        ),
        endAdornment: loading ? (
          <InputAdornment position="end">
            <CircularProgress size={20} />
          </InputAdornment>
        ) : null,
      }}
      {...other}
    />
  );
}
