import { LoadingButton } from '@mui/lab';
import { CalendarEdit, CalendarRemove, People, Trash } from 'iconsax-react';
import { Stack, Popover, Divider, Backdrop, Typography, PopoverPosition } from '@mui/material';
//
import { CalendarStoreResponseRightItem } from 'src/store/calendar/types';

interface Props {
  onClose(): void;
  anchorEl: HTMLElement;
  anchorPosition: PopoverPosition;
  calendarRightItem: CalendarStoreResponseRightItem;
}

export default function ProgramItemActions(props: Props) {
  const { onClose, anchorEl, anchorPosition, calendarRightItem } = props;

  return (
    <Backdrop open sx={{ zIndex: 10 }}>
      <Popover
        open
        onClose={onClose}
        anchorEl={anchorEl}
        anchorPosition={anchorPosition}
        anchorReference="anchorPosition"
        sx={{ transform: 'translateY(-16px)' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Stack
          sx={{
            width: 284,
            borderRadius: 1,
            overflow: 'hidden',
            bgcolor: 'background.default',
            '& > button': {
              py: 1,
              px: 2,
              gap: 0.25,
              color: 'text.primary',
              justifyContent: 'flex-start',
              '&:hover': { color: 'primary.main' },
            },
          }}
        >
          <Typography py={1.5} variant="subtitle2" fontWeight="700" textAlign="center">
            {calendarRightItem.point.name}
          </Typography>
          <Divider />
          <LoadingButton startIcon={<CalendarEdit variant="Bulk" size={20} />}>
            Plandan Çıkar
          </LoadingButton>
          <LoadingButton startIcon={<CalendarRemove variant="Bulk" size={20} />}>
            Takvimden Çıkar
          </LoadingButton>
          <LoadingButton startIcon={<People variant="Bulk" size={20} />}>
            Atamayı Kaldır
          </LoadingButton>
          <LoadingButton startIcon={<Trash variant="Bulk" size={20} />}>Taskı Sil</LoadingButton>
        </Stack>
      </Popover>
    </Backdrop>
  );
}
