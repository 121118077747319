import React from 'react';
import { Eye } from 'iconsax-react';
import { Box, Stack, Checkbox, IconButton, Typography, useTheme } from '@mui/material';
//
import { MapPointSearchResponseListItem } from 'src/store/map-points/types';
import useMapActionsStore, { mapActionsCenteredMap } from 'src/store/map-actions';

interface Props {
  selected: boolean;
  item: MapPointSearchResponseListItem;
}

const MapAsideWrapperTaskAssignmentPointsItem = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { item, selected } = props;
    const { palette } = useTheme();

    const handleChecked = () => {
      if (selected) {
        useMapActionsStore.setState((s) => ({
          selectedsPoints: s.selectedsPoints.filter((point) => point.id !== item.id),
        }));
      } else {
        useMapActionsStore.setState((s) => ({ selectedsPoints: [...s.selectedsPoints, item] }));
      }
    };

    return (
      <Stack
        ref={ref}
        direction="row"
        alignItems="center"
        sx={{
          px: 1.25,
          height: 58,
          borderRadius: 1,
          overflow: 'hidden',
          bgcolor: 'background.default',
        }}
      >
        <Box>
          <Checkbox disableRipple sx={{ pl: 0 }} onChange={handleChecked} checked={selected} />
        </Box>
        <Stack flex={1} overflow="hidden">
          <Typography
            overflow="hidden"
            variant="subtitle2"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {item.SIGN_NAME}
          </Typography>
          <Typography
            variant="caption"
            overflow="hidden"
            whiteSpace="nowrap"
            color="text.disabled"
            textOverflow="ellipsis"
          >
            {item.ADDRESS || '-'}
          </Typography>
        </Stack>
        <IconButton size="small" onClick={() => mapActionsCenteredMap([item])}>
          <Eye variant="Bulk" size={24} color={palette.text.disabled} />
        </IconButton>
      </Stack>
    );
  }
);

export default MapAsideWrapperTaskAssignmentPointsItem;
