import React from 'react';

import { Card, Typography } from '@mui/material';
//
import useFetch from 'src/hooks/use-fetch';
import SearchBox from 'src/components/search-box';
import InputWrapper from 'src/components/input-wrapper';

interface Props<T> {
  url: string;
  title: string;
  defaultValue: T;
  placeholder: string;
  renderData: (response: T) => {
    display: boolean;
    children: React.ReactNode;
  };
}

export default function CalendarFiltersContentOptions<T>(props: Props<T>) {
  const { url, title, placeholder, renderData, defaultValue } = props;
  const searchRef = React.useRef<any>(null);
  const [search, setSearch] = React.useState('');

  const newURL = `${url}${search ? `&search=${search}` : ''}`;

  const { data, loading, getData } = useFetch<T>({ url: newURL, defaultValue, initialStop: true });

  React.useEffect(() => {
    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    searchRef.current = setTimeout(() => {
      getData();
    }, 300);

    return () => {
      if (searchRef.current) {
        clearTimeout(searchRef.current);
      }
    };
  }, [getData, search]);

  const renderContent = () => {
    const { children, display } = renderData(data);

    if (!display) {
      return (
        <Typography variant="subtitle2" color="text.disabled" sx={{ textAlign: 'center', pt: 1.5 }}>
          Hiç sonuç bulunamadı.
        </Typography>
      );
    }

    return children;
  };

  return (
    <InputWrapper title={title}>
      <Card>
        <SearchBox
          fullWidth
          search={search}
          loading={loading}
          setSearch={setSearch}
          placeholder={placeholder}
          sx={{ bgcolor: 'background.default', borderRadius: 1 }}
        />
        {renderContent()}
      </Card>
    </InputWrapper>
  );
}
