import React from 'react';
//
import Table from './table';
import Header from './header';

interface Props {
  tableHeight: number;
}

export default function MapBottomWrapperTasks(props: Props) {
  const { tableHeight } = props;

  return (
    <>
      <Header />
      <Table tableHeight={tableHeight} />
    </>
  );
}
