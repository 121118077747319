import React from 'react';
import { CloseSquare, Menu } from 'iconsax-react';
import { Box, GlobalStyles, IconButton, Stack, Typography, useTheme } from '@mui/material';
//
import DashboardNavbarVertical from './vertical';
import LayoutNavbarProfileBox from './profile-box';

export const LayoutDashboardMobileHeaderSize = 64;

interface Props {
  hasMobile: boolean;
}

export default function LayoutDashboardNavbar(props: Props) {
  const { hasMobile } = props;
  const { palette } = useTheme();
  const [visible, setVisible] = React.useState(false);

  const overflowDisabled = visible && hasMobile ? { '*': { overflow: 'hidden!important' } } : null;

  const renderHeader = () => {
    return (
      <Stack width={1} direction="row" alignItems="center" sx={{ '*': overflowDisabled }}>
        <Stack flex={1} direction="row" spacing={1.5} alignItems="center">
          <Box
            width={36}
            height={36}
            component="img"
            src="/img/logo.svg"
            sx={{ objectFit: 'contain' }}
          />
          <Typography variant="h6">Scheduler</Typography>
        </Stack>
        {hasMobile && (
          <IconButton onClick={() => setVisible(!visible)}>
            {visible ? (
              <CloseSquare size={24} variant="Bulk" />
            ) : (
              <Menu variant={visible ? 'Bold' : 'Bulk'} size={20} />
            )}
          </IconButton>
        )}
      </Stack>
    );
  };

  return (
    <>
      <GlobalStyles styles={overflowDisabled} />
      {hasMobile && (
        <Stack
          px={2}
          py={3}
          top={0}
          zIndex={2}
          direction="row"
          position="sticky"
          alignItems="center"
          bgcolor="background.default"
          height={LayoutDashboardMobileHeaderSize}
        >
          {renderHeader()}
        </Stack>
      )}
      <Stack
        px={2}
        py={3}
        spacing={2}
        flexShrink={0}
        component="nav"
        sx={{
          inset: 0,
          zIndex: 2,
          overflowY: 'auto',
          width: hasMobile ? 1 : 240,
          bgcolor: 'background.default',
          transition: 'transform 0.2s ease',
          position: hasMobile ? 'fixed' : 'initial',
          borderRight: hasMobile ? `1px solid ${palette.divider}` : 'none',
          transform: hasMobile ? (visible ? 'translateX(0)' : 'translateX(-100%)') : 'initial',
        }}
      >
        {renderHeader()}
        <DashboardNavbarVertical />
        <LayoutNavbarProfileBox />
      </Stack>
    </>
  );
}
