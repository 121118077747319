import React from 'react';
import { Chip, Stack, Tab } from '@mui/material';
//
import { AsideWrapperTabsItem } from './aside-tabs';

interface Props {
  tabs: AsideWrapperTabsItem[];
  activeTab: AsideWrapperTabsItem;
  handleTabChange: (tab: AsideWrapperTabsItem) => void;
}

export default function AsideWrapperTabsCustom(props: Props) {
  const { tabs, activeTab, handleTabChange } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    const tab = tabs.find((tab) => tab.id === newValue);

    if (tab) {
      handleTabChange(tab);
    }
  };

  const style = {
    pl: 1,
    flex: 1,
    fontSize: 12,
    minHeight: 32,
    borderRadius: 1,
    mr: '0!important',
    color: 'text.disabled',
    whiteSpacing: 'nowrap',
    justifyContent: 'space-between',
  };
  const activeStyle = { bgcolor: 'background.default', color: 'text.primary' };

  return (
    <Stack
      p={0.5}
      direction="row"
      borderRadius={1}
      bgcolor={(t) => `${t.palette.background.neutral}50`}
    >
      {tabs.map((tab) => {
        const active = tab.id === activeTab.id;
        return (
          <Tab
            key={tab.id}
            disableRipple
            value={tab.id}
            label={tab.label}
            iconPosition="end"
            sx={[style, active && activeStyle]}
            onClick={(event) => handleChange(event, tab.id)}
            icon={
              tab.countHidden ? undefined : (
                <Chip
                  size="small"
                  variant="ghost"
                  label={tab.count}
                  color={active ? 'primary' : 'default'}
                />
              )
            }
          />
        );
      })}
    </Stack>
  );
}
