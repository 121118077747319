import React from 'react';
import { Chip, Tab, Tabs } from '@mui/material';

export type AsideWrapperTabsItem = {
  id: string;
  label: string;
  count: number;
  countHidden?: boolean;
};

interface Props {
  tabs: AsideWrapperTabsItem[];
  activeTab: AsideWrapperTabsItem;
  handleTabChange: (tab: AsideWrapperTabsItem) => void;
}

export default function AsideWrapperTabs(props: Props) {
  const { tabs, activeTab, handleTabChange } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    const tab = tabs.find((tab) => tab.id === newValue);

    if (tab) {
      handleTabChange(tab);
    }
  };

  const style = {
    pl: 1,
    flex: 1,
    fontSize: 12,
    minHeight: 40,
    borderRadius: 0,
    mr: '0!important',
    color: 'text.disabled',
    whiteSpacing: 'nowrap',
    justifyContent: 'space-between',
  };
  const activeStyle = { bgcolor: 'background.default', color: 'text.primary' };

  return (
    <Tabs value={activeTab.id} onChange={handleChange} sx={{ minHeight: 40 }}>
      {tabs.map((tab) => {
        const active = tab.id === activeTab.id;
        return (
          <Tab
            key={tab.id}
            value={tab.id}
            iconPosition="end"
            label={tab.label}
            sx={[style, active && activeStyle]}
            icon={
              tab.countHidden ? undefined : (
                <Chip
                  size="small"
                  variant="ghost"
                  label={tab.count}
                  color={active ? 'primary' : 'default'}
                />
              )
            }
          />
        );
      })}
    </Tabs>
  );
}
