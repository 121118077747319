import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, CircularProgress, Stack } from '@mui/material';
//
import CalendarAsidePointsListItem from './item';
import useCalendarAsideStore from 'src/store/calendar-aside';
import CalendarAsidePersonsWrapperEmpty from '../persons-teams-pools/persons/empty';

export default function CalendarAsideWrapperPoints() {
  const calendarAsideStore = useCalendarAsideStore(useShallow((s) => s));

  if (calendarAsideStore.response.points.length === 0) {
    return (
      <CalendarAsidePersonsWrapperEmpty
        title="Seçili tüm tasklarınız planlandı!"
        description="Planlanmamış görevleriniz varsa, bu görevler bu alanda görünecektir. Ayrıca, takvimdeki
          herhangi bir kişiye atanmış bir görevi ‘Unplaned’ olarak işaretlediğinizde, görev otomatik
          olarak bu alana taşınacaktır."
      />
    );
  }

  const nextFetch = () => {
    // mapAsidePersonsStore.paginationLoading
    if (false) {
      return;
    }

    // useMapAsidePersonsStore.setState({ paginationLoading: true });
    // getMapsAsidePersonsSearch(mapAsidePersonsStore.search, true);
  };

  return (
    <Box
      id="points-scrollable-div"
      sx={{
        p: 1.5,
        flex: 1,
        overflow: 'auto',
        '&': { scrollbarWidth: 'none' },
        '&::-webkit-scrollbar': { display: 'none' },
        '& .infinite-scroll-component': { display: 'flex', flexDirection: 'column', gap: 1.25 },
      }}
    >
      <InfiniteScroll
        hasMore={false}
        next={nextFetch}
        scrollableTarget="persons-teams-pools-scrollable-div"
        dataLength={calendarAsideStore.response.teams.length}
        loader={
          false ? (
            <Stack height={20} overflow="hidden" alignItems="center">
              <CircularProgress size={20} />
            </Stack>
          ) : null
        }
        // endMessage={
        //   <Typography variant="subtitle2" textAlign="center" color="text.disabled">
        //     Listenin sonuna geldiniz...
        //   </Typography>
        // }
      >
        {calendarAsideStore.response.points.map((point, index) => {
          return <CalendarAsidePointsListItem key={index} point={point} index={index} />;
        })}
      </InfiniteScroll>
    </Box>
  );
}
