import React from 'react';
import { Stack, CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
//
import Collapse from './collapse';

interface Props {}

export default function MapAsideSubWrapperPointDetailCollapses(props: Props) {
  const items: any[] = [...new Array(30)].map((_, index) => ({
    ...{
      id: `${index}`,
      user: {
        avatar: '',
        id: `${index}`,
        isOnline: true,
        name: index % 2 === 0 ? 'Yasir Kavraş' : 'Mehmet Güraydın',
      },
      tasksCount: index + 1,
      tasksPercent: Math.floor(Math.random() * 100) + 1,
    },
  }));

  const fetchData = () => {
    console.log('fetchData');
  };

  return (
    <InfiniteScroll
      hasMore={false}
      next={fetchData}
      dataLength={items.length}
      scrollableTarget="points-detail-scrollable-div"
      loader={
        <Stack height={20} overflow="hidden" alignItems="center">
          <CircularProgress size={20} />
        </Stack>
      }
    >
      {items.map((item, index) => (
        <Collapse key={index} item={item} />
      ))}
    </InfiniteScroll>
  );
}
