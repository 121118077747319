import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { Chip, Stack, Typography } from '@mui/material';
import { Calendar, CalendarRemove } from 'iconsax-react';
//
import useMapsLayoutStore from 'src/store/maps-layout';
import useMapsBottomTasksStore from 'src/store/maps-bottom-tasks';
import { MapsLayoutBottomTabsEnum } from 'src/store/maps-layout/types';
import useMapsBottomTasksToPlannedStore from 'src/store/maps-bottom-tasks-to-planned';

type CustomTabProps = {
  title: string;
  onClick?(): void;
  active?: boolean;
  icon: React.ReactNode;
  action?: React.ReactNode;
};
const CustomTab = (props: CustomTabProps) => {
  const { title, action, active, onClick, icon } = props;

  return (
    <Stack
      p={1.2}
      spacing={0.5}
      direction="row"
      onClick={onClick}
      alignItems="center"
      sx={{
        borderRadius: 1,
        cursor: 'pointer',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        bgcolor: active ? 'background.neutral' : 'transparent',
        '&:hover': { bgcolor: 'background.neutral' },
      }}
    >
      {icon}

      <Typography variant="subtitle2">{title}</Typography>
      {action}
    </Stack>
  );
};

export default function MapBottomWrapperTasksHeaderTabs() {
  const mapsLayoutStore = useMapsLayoutStore(useShallow((s) => s));
  const mapsBottomTasksStore = useMapsBottomTasksStore(useShallow((s) => s));
  const mapsBottomTasksToPlannedStore = useMapsBottomTasksToPlannedStore(useShallow((s) => s));

  const onChangeTab = (tab: MapsLayoutBottomTabsEnum) => {
    mapsBottomTasksStore.onRowSelectionChange({});
    useMapsLayoutStore.setState({ bottomActiveTab: tab });
  };

  return (
    <Stack direction="row">
      <CustomTab
        title="Taskler"
        icon={<CalendarRemove size={20} variant="Bulk" />}
        active={mapsLayoutStore.bottomActiveTab === MapsLayoutBottomTabsEnum.TASKS}
        onClick={() => {
          onChangeTab(MapsLayoutBottomTabsEnum.TASKS);
        }}
        action={
          <Chip
            size="small"
            variant="ghost"
            label={`Toplam: ${mapsBottomTasksStore.response.totalElements}`}
          />
        }
      />
      <CustomTab
        title="Planlanacak"
        icon={<Calendar size={20} variant="Bulk" />}
        active={mapsLayoutStore.bottomActiveTab === MapsLayoutBottomTabsEnum.TASKS_TO_PLANNED}
        action={
          <Chip
            size="small"
            variant="ghost"
            label={`Toplam: ${mapsBottomTasksToPlannedStore.response.totalElements}`}
          />
        }
        onClick={() => {
          onChangeTab(MapsLayoutBottomTabsEnum.TASKS_TO_PLANNED);
        }}
      />
    </Stack>
  );
}
