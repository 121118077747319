import { create } from 'zustand';
//
import axios, { endpoints } from 'src/utils/axios';
import { GeomarketingFiltersStoreType, GeomarketingFiltersType } from './types';

const initialState: GeomarketingFiltersStoreType = {
  list: [],
  error: false,
  loading: true,
};

const useGeomarketingFiltersStore = create(() => ({
  ...initialState,
}));

export default useGeomarketingFiltersStore;

const { setState } = useGeomarketingFiltersStore;

// REQUEST
export const getAllGeomarketingFilters = async () => {
  setState({ loading: true });
  try {
    const response = await axios.get<GeomarketingFiltersType[]>(endpoints.geomarketingFilters.list);
    setState({ list: response.data });
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};
