import { create } from 'zustand';
//
import axios, { endpoints } from 'src/utils/axios';
import { GetAllTasksRequestType, TaskItem, TasksStore } from './types';

const initialState: TasksStore = {
  list: [],
  error: false,
  totalPages: 0,
  loading: true,
  totalElements: 0,
  // Pagination custom
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
};

const useTasksStore = create<TasksStore>((set) => ({
  ...initialState,
}));

export default useTasksStore;

const { setState, getState } = useTasksStore;

// REQUESTS
export const getAllTasks = async (body?: GetAllTasksRequestType) => {
  setState(initialState);
  try {
    const response = await axios.post(endpoints.task.list, {
      ...(body || {
        size: getState().pagination.pageSize,
        page: getState().pagination.pageIndex + 1,
      }),
      ascending: false,
      sortBy: 'id',
    });
    setState(response.data);
  } catch (error) {
    setState({ error: true });
  } finally {
    setState({ loading: false });
  }
};

export const onPaginationChange = async (updater: any) => {
  const oldPagination = getState().pagination;

  const newPagination = updater(oldPagination);

  await getAllTasks({ page: newPagination.pageIndex + 1, size: newPagination.pageSize });

  setState({ pagination: newPagination });
};

export const deleteTask = async (item: TaskItem) => {
  try {
    await axios.delete(endpoints.task.delete(item.id));
    await getAllTasks();
  } catch (error) {
    throw error;
  }
};
