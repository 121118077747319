export type TaskDefinitionFormStoreType = {
  functions: TaskDefinitionFormStoreFunctionsType[];
};

export type TaskDefinitionFormStoreFunctionsType = {
  type: string;
  detail: string;
  orderNo: number;
  mandatory: boolean;
  rejectReasonIdList: number[];
  attributes: Array<{
    type?: string;
    unitType?: string;
    unitValue?: number;
    key: string | number;
    value: string | number;
  }>;
};

export enum TaskDefinitionFormStoreTaskStatusEnum {
  CREATED = 'CREATED',
  IN_POOL = 'IN_POOL',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  POSTPONED = 'POSTPONED',
  CREATED_PLANNED = 'CREATED_PLANNED',
  CREATED_TEAM_ASSIGN = 'CREATED_TEAM_ASSIGN',
  CREATED_USER_ASSIGNED = 'CREATED_USER_ASSIGNED',
  CREATED_USER_ASSIGN_PLANNED = 'CREATED_USER_ASSIGN_PLANNED',
  CREATED_TEAM_ASSIGN_PLANNED = 'CREATED_TEAM_ASSIGN_PLANNED',
  CREATED_POOL_ASSIGN_PLANNED = 'CREATED_POOL_ASSIGN_PLANNED',
  CREATED_ROLE_ASSIGN_PLANNED = 'CREATED_ROLE_ASSIGN_PLANNED',
}

export const taskDefinitionFormTaskStatus: Record<TaskDefinitionFormStoreTaskStatusEnum, string> = {
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED]: 'Oluşturuldu',
  [TaskDefinitionFormStoreTaskStatusEnum.IN_POOL]: 'Havuza Atandı',
  [TaskDefinitionFormStoreTaskStatusEnum.COMPLETED]: 'Tamamlandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CANCELLED]: 'İptal Edildi',
  [TaskDefinitionFormStoreTaskStatusEnum.POSTPONED]: 'Ertelendi',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_PLANNED]: 'Planlandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_TEAM_ASSIGN]: 'Ekibe Atandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_USER_ASSIGNED]: 'Kişiye Atandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_ROLE_ASSIGN_PLANNED]: 'Role Atanarak Planlandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_TEAM_ASSIGN_PLANNED]: 'Ekibe Atanarak Planlandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_USER_ASSIGN_PLANNED]: 'Kişiye Atanarak Planlandı',
  [TaskDefinitionFormStoreTaskStatusEnum.CREATED_POOL_ASSIGN_PLANNED]: 'Havuza Atanarak Planlandı',
};

export const taskDefinitionFormTaskStatusOptions = Object.entries(taskDefinitionFormTaskStatus).map(
  ([key, value]) => ({
    id: key,
    name: value,
  })
) as Array<{ id: TaskDefinitionFormStoreTaskStatusEnum; name: string }>;
