import React from 'react';
import { Box, Stack } from '@mui/material';

type Props = {
  tableHeight: number;
};

const tableTHeadHeight = 35.4;

export default function TableEmptyList(props: Props) {
  const { tableHeight } = props;

  const newHeight = tableHeight - tableTHeadHeight;

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{ flex: 1, height: newHeight }}
    >
      <Box
        component="img"
        alt="empty list"
        src="/img/empty.svg"
        sx={{ transition: '.4s ease', width: 'auto', height: newHeight / 2 }}
      />
    </Stack>
  );
}
