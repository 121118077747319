import moment from 'moment';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
//
import { CalendarLayoutDayView, CalendarLayoutStore } from './types';
import {
  taskDefinitionFormTaskStatus,
  TaskDefinitionFormStoreTaskStatusEnum,
} from '../task-definition-form/types';

const initialState: CalendarLayoutStore = {
  asideVisible: true,
  bottomVisible: true,
  //
  date: moment().startOf('dates').format('YYYY-MM-DDTHH:mm:ss'),
  dayView: CalendarLayoutDayView.Day,
  // Filters
  filtersVisible: false,
  filters: {
    selectedsPersons: [],
    selectedsTeams: [],
    selectedsPools: [],
    // Planlandı tamamlandı ertelendi iptal edildi
    statuses: [
      {
        color: '#8E33FF',
        checked: true,
        key: TaskDefinitionFormStoreTaskStatusEnum.CREATED_PLANNED,
        keys: [
          TaskDefinitionFormStoreTaskStatusEnum.CREATED,
          TaskDefinitionFormStoreTaskStatusEnum.CREATED_PLANNED,
          TaskDefinitionFormStoreTaskStatusEnum.CREATED_USER_ASSIGN_PLANNED,
          TaskDefinitionFormStoreTaskStatusEnum.CREATED_TEAM_ASSIGN_PLANNED,
          TaskDefinitionFormStoreTaskStatusEnum.CREATED_POOL_ASSIGN_PLANNED,
          TaskDefinitionFormStoreTaskStatusEnum.CREATED_ROLE_ASSIGN_PLANNED,
        ],
        title: taskDefinitionFormTaskStatus[TaskDefinitionFormStoreTaskStatusEnum.CREATED_PLANNED],
      },
      {
        keys: [],
        color: '#4CAF50',
        checked: true,
        key: TaskDefinitionFormStoreTaskStatusEnum.COMPLETED,
        title: taskDefinitionFormTaskStatus[TaskDefinitionFormStoreTaskStatusEnum.COMPLETED],
      },
      {
        keys: [],
        color: '#FFC107',
        checked: true,
        key: TaskDefinitionFormStoreTaskStatusEnum.POSTPONED,
        title: taskDefinitionFormTaskStatus[TaskDefinitionFormStoreTaskStatusEnum.POSTPONED],
      },
      {
        keys: [],
        color: '#FF5722',
        checked: true,
        key: TaskDefinitionFormStoreTaskStatusEnum.CANCELLED,
        title: taskDefinitionFormTaskStatus[TaskDefinitionFormStoreTaskStatusEnum.CANCELLED],
      },
      {
        key: '',
        keys: [
          TaskDefinitionFormStoreTaskStatusEnum.CREATED_TEAM_ASSIGN,
          TaskDefinitionFormStoreTaskStatusEnum.CREATED_USER_ASSIGNED,
        ],
        checked: true,
        color: '#C4CDD5',
        title: 'Planlanmamış',
      },
    ],
  },
};

const useCalendarLayoutStore = create(
  persist<CalendarLayoutStore & { reset(): void }>(
    (set) => ({
      ...initialState,
      reset: () => set((s) => ({ ...s, filtersVisible: false, filters: initialState.filters })),
    }),
    {
      name: 'calendar-layout',
      // LocalStorage'de tutulacak alanları belirtiyoruz.
      partialize: (s) => {
        return {
          dayView: s.dayView,
          asideVisible: s.asideVisible,
          bottomVisible: s.bottomVisible,
        } as CalendarLayoutStore & { reset(): void };
      },
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useCalendarLayoutStore;
