import React from 'react';
import { Card, CircularProgress, Stack, SxProps } from '@mui/material';

type Props = {
  sx?: SxProps;
};

export default function Loading(props: Props) {
  return (
    <Card
      component={Stack}
      alignItems="center"
      justifyContent="center"
      sx={{ inset: 0, position: 'absolute', zIndex: 9, ...props.sx }}
    >
      <CircularProgress size={50} />
    </Card>
  );
}
