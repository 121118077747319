import React from 'react';
import { Card, IconProps } from 'iconsax-react';
import { Stack, Typography, useTheme } from '@mui/material';

interface Props {
  icon?: any;
  title: string;
  description?: string;
  iconVariant?: IconProps['variant'];
}

export default function ModalHeader(props: Props) {
  const { palette } = useTheme();
  const { title, description, icon, iconVariant } = props;

  const IconComponent: any = icon || Card;

  return (
    <Stack spacing={2.5} sx={{ p: { xs: 1.5, lg: 3 }, textAlign: 'center', alignItems: 'center' }}>
      <Stack
        sx={{
          width: 48,
          height: 48,
          borderRadius: 6,
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: palette.primary[100],
        }}
      >
        <Stack
          sx={{
            width: 38,
            height: 38,
            borderRadius: 6,
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: palette.primary[200],
          }}
        >
          <IconComponent variant={iconVariant || 'Linear'} color={palette.primary.main} size={20} />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Stack>
    </Stack>
  );
}
