import * as React from 'react';
import moment from 'moment';
import 'moment/min/locales';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { ArrowLeft2, ArrowRight2, CalendarEdit, CalendarTick, Filter } from 'iconsax-react';
//
import { useShallow } from 'zustand/react/shallow';
import ButtonIcon from 'src/components/button-icon';
import useCalendarLayoutStore from 'src/store/calendar-layout';
import { CalendarLayoutDayView } from 'src/store/calendar-layout/types';
import useCalendarStore, { calendarCreateBulk, getCalendarData } from 'src/store/calendar';

const DayNames: Record<CalendarLayoutDayView, string> = {
  day: 'Gün',
  week: 'Hafta',
  month: 'Ay',
};

export default function LayoutCalendarOptions() {
  const { palette } = useTheme();
  const { date, filters } = useCalendarLayoutStore((s) => ({
    date: s.date,
    filters: s.filters,
  }));
  const { responseRight, createBulkLoading } = useCalendarStore((s) => ({
    responseRight: s.responseRight,
    createBulkLoading: s.createBulkLoading,
  }));

  const handlePrevAndNext = (type: 'prev' | 'next') => {
    const newDate =
      type === 'prev' ? moment(date).subtract(1, 'days') : moment(date).add(1, 'days');

    // Fetch new data
    getCalendarData(moment(newDate).startOf('dates').format('YYYY-MM-DDTHH:mm:ss'));
  };

  const calendarLayoutStore = useCalendarLayoutStore(useShallow((s) => s));

  const handleChange = () => {
    if (calendarLayoutStore.dayView === CalendarLayoutDayView.Day) {
      useCalendarLayoutStore.setState({ dayView: CalendarLayoutDayView.Week });
    } else if (calendarLayoutStore.dayView === CalendarLayoutDayView.Week) {
      useCalendarLayoutStore.setState({ dayView: CalendarLayoutDayView.Month });
    } else {
      useCalendarLayoutStore.setState({ dayView: CalendarLayoutDayView.Day });
    }
  };

  return (
    <Stack
      flexShrink={0}
      alignItems="center"
      pl={{ xs: 0, md: 1.5 }}
      py={{ xs: 1.5, md: 0 }}
      bgcolor="background.default"
      height={{ xs: 'auto', md: 48 }}
      direction={{ xs: 'column', md: 'row' }}
    >
      <Stack flex={1} direction="row" alignItems="center" spacing={1}>
        <Typography variant="subtitle1">
          {moment(date).locale('tr').format('DD MMMM YYYY')}
        </Typography>
        <ButtonIcon
          title="Geri"
          onClick={() => handlePrevAndNext('prev')}
          sx={{ width: 30, height: 30, bgcolor: 'background.neutral' }}
        >
          <ArrowLeft2 size={20} />
        </ButtonIcon>
        <ButtonIcon
          title="İleri"
          onClick={() => handlePrevAndNext('next')}
          sx={{ width: 30, height: 30, bgcolor: 'background.neutral' }}
        >
          <ArrowRight2 size={20} />
        </ButtonIcon>
      </Stack>
      <Stack direction="row" spacing={0.75} alignItems="center">
        <Stack
          spacing={1.5}
          direction="row"
          alignItems="center"
          sx={{
            py: 1,
            px: 1.5,
            borderRadius: 1,
            cursor: 'pointer',
            bgcolor: 'background.neutral',
          }}
          onClick={() => {
            useCalendarLayoutStore.setState({ filtersVisible: true });
          }}
        >
          <Tooltip title="Filtre">
            <Filter variant="Bulk" size={20} />
          </Tooltip>
          <Stack direction="row" spacing={0.375}>
            {filters.statuses.map((status, index) => {
              return (
                <Tooltip key={index} title={status.title}>
                  <Box
                    sx={{
                      width: 14,
                      height: 14,
                      borderRadius: 2,
                      bgcolor: status.color,
                      opacity: status.checked ? 1 : 0.25,
                    }}
                  ></Box>
                </Tooltip>
              );
            })}
          </Stack>
        </Stack>
        <LoadingButton
          variant="contained"
          loadingPosition="end"
          loading={createBulkLoading}
          onClick={calendarCreateBulk}
          disabled={responseRight.length === 0}
          endIcon={<CalendarTick variant="Bulk" size={20} />}
        >
          Planı Kaydet
        </LoadingButton>
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          // onClick={handleChange}
          sx={{
            px: 2.5,
            height: 48,
            cursor: 'pointer',
            userSelect: 'none',
            bgcolor: 'background.neutral',
          }}
        >
          <Stack textAlign="right" spacing={-0.5}>
            <Typography variant="subtitle2" color="text.secondary" fontSize={12}>
              Takvim Görünümü:
            </Typography>
            <Typography variant="subtitle1" color="primary">
              {DayNames[calendarLayoutStore.dayView]}
            </Typography>
          </Stack>
          <CalendarEdit size={34} variant="Bulk" color={palette.primary.main} />
        </Stack>
      </Stack>
    </Stack>
  );
}
