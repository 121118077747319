import React from 'react';
//
import { MapAsideWrapperTaskAssignment } from 'src/components/map/aside-wrapper';

interface Props {}

export default function LayoutMapAside(props: Props) {
  // Sayfaya göre burası değişecek

  // TaskPlanning vs vs

  return <MapAsideWrapperTaskAssignment />;
}
