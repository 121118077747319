import React from 'react';
//
import LayoutDashboard from 'src/layouts/dashboard';
//
const SettingReasonsRejectionPage = React.lazy(
  () => import('src/pages/settings/reasons-rejection')
);
const SettingApplicationSettingsPage = React.lazy(
  () => import('src/pages/settings/application-settings')
);

const SettingRejectionPage = React.lazy(() => import('src/pages/settings/rejection'));

export const settingsRoutes = [
  {
    path: 'settings',
    element: <LayoutDashboard />,
    children: [
      { element: <SettingReasonsRejectionPage />, index: true },
      { path: 'reasons-rejection', element: <SettingReasonsRejectionPage /> },
      { path: 'application-settings', element: <SettingApplicationSettingsPage /> },
      { path: 'rejection', element: <SettingRejectionPage /> },
    ],
  },
];
