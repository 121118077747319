//
import React from 'react';
import { CloseIcon } from './CustomIcons';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    ghost: true;
  }
}

// ----------------------------------------------------------------------

export default function Chip(theme: any) {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseIcon />,
      },

      styleOverrides: {
        colorDefault: {
          borderRadius: '4px',
          '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
            color: theme.palette.text.secondary,
          },
        },
        outlined: {
          borderRadius: '4px',
          borderColor: theme.palette.grey[500_32],
          '&.MuiChip-colorPrimary': {
            borderColor: theme.palette.primary.main,
          },
          '&.MuiChip-colorSecondary': {
            borderColor: theme.palette.secondary.main,
          },
        },
        ghost: {
          borderRadius: '4px',
          '&.MuiChip-colorPrimary': {
            backgroundColor: theme.palette.primary[100],
            color: theme.palette.primary.main,
            fontWeight: '600',
          },
          '&.MuiChip-colorSecondary': {
            backgroundColor: theme.palette.secondary.lighter,
            color: theme.palette.secondary.main,
            fontWeight: '600',
          },
          '&.MuiChip-colorError': {
            backgroundColor: theme.palette.error.lighter,
            color: theme.palette.error.main,
            fontWeight: '600',
          },
          '&.MuiChip-colorSuccess': {
            backgroundColor: theme.palette.success.lighter,
            color: theme.palette.success.main,
            fontWeight: '600',
          },
          '&.MuiChip-colorInfo': {
            backgroundColor: theme.palette.info.lighter,
            color: theme.palette.info.darker,
            fontWeight: '600',
          },
          '&.MuiChip-colorWarning': {
            backgroundColor: theme.palette.warning.lighter,
            color: theme.palette.warning.darker,
            fontWeight: '600',
          },
        },
        //
        avatarColorInfo: {
          color: theme.palette.info.contrastText,
          backgroundColor: theme.palette.info.dark,
        },
        avatarColorSuccess: {
          color: theme.palette.success.contrastText,
          backgroundColor: theme.palette.success.dark,
        },
        avatarColorWarning: {
          color: theme.palette.warning.contrastText,
          backgroundColor: theme.palette.warning.dark,
        },
        avatarColorError: {
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.dark,
        },
      },
    },
  };
}
