import { memo } from 'react';

import Box, { BoxProps } from '@mui/material/Box';

import BackgroundShape from './background-shape';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  iconColor: string;
}

function MarkerIllustration({ iconColor, ...other }: Props) {
  return (
    <Box
      fill="none"
      width="100%"
      height="100%"
      component="svg"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <BackgroundShape />

      <path
        fill={iconColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 20C6.39314 20 0.665527 14.3682 0.665527 8.37942C0.665527 6.15706 1.64897 4.02572 3.39952 2.45428C5.15007 0.882829 7.52432 0 9.99996 0C12.4756 0 14.8499 0.882829 16.6004 2.45428C18.351 4.02572 19.3344 6.15706 19.3344 8.37942C19.3344 14.3682 13.6077 20 9.99996 20Z"
      />
    </Box>
  );
}

export default memo(MarkerIllustration);
