import React from 'react';
import { useShallow } from 'zustand/react/shallow';
import { Stack, CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
//
import CalendarAsidePersonsWrapperEmpty from './empty';
import CalendarAsidePersonsWrapperListItem from './item';
import useCalendarAsideStore from 'src/store/calendar-aside';

export default function CalendarAsidePersonsWrapper() {
  const calendarAsideStore = useCalendarAsideStore(useShallow((s) => s));

  if (calendarAsideStore.response.users.length === 0) {
    return (
      <CalendarAsidePersonsWrapperEmpty
        title="Seçili tüm tasklarınız planlandı!"
        description="Planlanmamış görevleriniz varsa, bu görevler bu alanda görünecektir. Ayrıca, takvimdeki
          herhangi bir kişiye atanmış bir görevi ‘Unplaned’ olarak işaretlediğinizde, görev otomatik
          olarak bu alana taşınacaktır."
      />
    );
  }

  const nextFetch = () => {
    // mapAsidePersonsStore.paginationLoading
    if (false) {
      return;
    }

    // useMapAsidePersonsStore.setState({ paginationLoading: true });
    // getMapsAsidePersonsSearch(mapAsidePersonsStore.search, true);
  };

  return (
    <InfiniteScroll
      hasMore={false}
      next={nextFetch}
      scrollableTarget="persons-teams-pools-scrollable-div"
      dataLength={calendarAsideStore.response.users.length}
      loader={
        false ? (
          <Stack height={20} overflow="hidden" alignItems="center">
            <CircularProgress size={20} />
          </Stack>
        ) : null
      }
      // endMessage={
      //   <Typography variant="subtitle2" textAlign="center" color="text.disabled">
      //     Listenin sonuna geldiniz...
      //   </Typography>
      // }
    >
      {calendarAsideStore.response.users.map((item, index) => {
        return (
          <CalendarAsidePersonsWrapperListItem
            key={index}
            item={item}
            selectedsPoints={calendarAsideStore.selectedsPoints}
          />
        );
      })}
    </InfiniteScroll>
  );
}
