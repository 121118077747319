import React from 'react';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { Box, Button, Chip, Dialog, Stack, Typography } from '@mui/material';
import { Calendar, Calendar2, CalendarAdd, SearchNormal } from 'iconsax-react';
//
import { paths } from 'src/routes/paths';
import MapBottomWrapperTasksHeaderTabs from '../tabs';
import ModalHeader from 'src/components/modals/header';
import ModalFooter from 'src/components/modals/footer';
import ModalContent from 'src/components/modals/content';
import useMapsBottomTasksToPlannedStore, {
  handleAutoPlan,
  handleManuelPlan,
  getMapsBottomAllTasksToPlanned,
} from 'src/store/maps-bottom-tasks-to-planned';

export default function MapBottomWrapperTasksToPlannedHeader() {
  const searchRef = React.useRef<any>(null);
  const navigate = useNavigate();
  const [visibleModal, setVisibleModal] = React.useState<'manuel' | 'auto' | null>(null);
  const mapsBottomTasksToPlannedStore = useMapsBottomTasksToPlannedStore(useShallow((s) => s));

  const isManuel = visibleModal === 'manuel';

  const selectedsTasksIDS = React.useMemo(() => {
    return Object.entries(mapsBottomTasksToPlannedStore.rowSelection)
      .map((t) => t[0])
      .filter(Boolean);
  }, [mapsBottomTasksToPlannedStore.rowSelection]);

  const selectedsTasks = React.useMemo(() => {
    return mapsBottomTasksToPlannedStore.response.list.filter((t) =>
      selectedsTasksIDS.includes(`${t.id}`)
    );
  }, [mapsBottomTasksToPlannedStore.response.list, selectedsTasksIDS]);

  const totalCounts = React.useMemo(() => {
    return selectedsTasks.reduce(
      (acc, task) => {
        return {
          teamId: acc.teamId + task.assignments.filter((t) => t.teamId).length,
          poolId: acc.poolId + task.assignments.filter((t) => t.poolId).length,
          roleId: acc.roleId + task.assignments.filter((t) => t.roleId).length,
          employeeId: acc.employeeId + task.assignments.filter((t) => t.employeeId).length,
        };
      },
      { teamId: 0, poolId: 0, roleId: 0, employeeId: 0 }
    );
  }, [selectedsTasks]);

  const onChangeSearch = (search: string) => {
    useMapsBottomTasksToPlannedStore.setState({ search });

    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    searchRef.current = setTimeout(() => {
      getMapsBottomAllTasksToPlanned({
        page: 1,
        searchBy: 'name',
        searchKey: search,
        size: mapsBottomTasksToPlannedStore.pagination.pageSize,
      });
    }, 500);
  };

  const handleOnSubmit = async () => {
    try {
      if (isManuel) {
        await handleManuelPlan(selectedsTasksIDS);
        navigate(paths.calendar);
      } else {
        await handleAutoPlan(selectedsTasksIDS);
      }
    } catch (error) {
      const message = isManuel ? 'Manuel' : 'Otomatik';
      enqueueSnackbar(`${message} planlama işlemi başarısız oldu.`, { variant: 'error' });
    }
  };

  return (
    <Stack height={96} flexShrink={0} px={3} pt={1.5} justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <Stack flex={1} direction="row" alignItems="center">
          <SearchNormal size={20} variant="Bulk" />
          <Box
            type="text"
            component="input"
            placeholder="Planlanacak task adına göre ara..."
            value={mapsBottomTasksToPlannedStore.search}
            onChange={(e) => onChangeSearch(e.target.value)}
            sx={{
              px: 1,
              width: 210,
              height: 36,
              fontSize: 12,
              border: 'none',
              outline: 'none',
              fontWeight: 600,
              minWidth: 'auto',
              color: 'text.primary',
              bgcolor: 'background.default',
              '::placeholder': { color: 'text.primary' },
            }}
          />
          <Chip
            size="small"
            variant="ghost"
            color="primary"
            label={`Listelenen: ${mapsBottomTasksToPlannedStore.response.list.length}`}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1.25}>
          <Button
            variant="contained"
            onClick={() => setVisibleModal('manuel')}
            endIcon={<Calendar2 size={20} variant="Bulk" />}
            disabled={mapsBottomTasksToPlannedStore.rowSelectionCount === 0}
          >
            Manuel Planla
          </Button>
          <Button
            disabled
            color="secondary"
            variant="contained"
            onClick={() => setVisibleModal('auto')}
            endIcon={<Calendar size={20} variant="Bulk" />}
            // disabled={mapsBottomTasksToPlannedStore.rowSelectionCount === 0}
          >
            Otomatik Planla
          </Button>
        </Stack>
      </Stack>
      <MapBottomWrapperTasksHeaderTabs />
      <Dialog open={visibleModal !== null} fullWidth maxWidth="sm">
        <ModalHeader
          icon={CalendarAdd}
          iconVariant="Bulk"
          title={`Seçimleriniz ${isManuel ? 'Manuel' : 'Otomatik'} Planlanacaktır`}
        />
        <ModalContent>
          <Typography variant="subtitle2">
            <b>{totalCounts.employeeId} Kişi</b>, <b>{totalCounts.teamId} Ekip</b> ve{' '}
            <b>{totalCounts.poolId} Havuza </b>
            atadığınız toplam <b>{selectedsTasks.length} Task</b> manuel planlanacaktır. Devam etmek
            istiyor musunuz?
          </Typography>
        </ModalContent>
        <ModalFooter
          onClose={() => setVisibleModal(null)}
          actions={
            <LoadingButton
              variant="contained"
              loadingPosition="end"
              onClick={handleOnSubmit}
              endIcon={<Calendar size={20} variant="Bulk" />}
              loading={mapsBottomTasksToPlannedStore.handlePlannedLoading}
            >
              Devam Et
            </LoadingButton>
          }
        />
      </Dialog>
    </Stack>
  );
}
