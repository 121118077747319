import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
//
import ThemeProviderConfigurations from './configurations';

// ----------------------------------------------------------------------

export default function CustomThemeProvider({ children }: any) {
  const themeOptions = ThemeProviderConfigurations();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeOptions}>
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
