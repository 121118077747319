import React from 'react';
//
import LayoutCalendar from 'src/layouts/calendar';

const CalendarPage = React.lazy(() => import('src/pages/calendar'));

export const calendaarRoutes = [
  {
    path: 'calendar',
    element: <LayoutCalendar />,
    children: [{ element: <CalendarPage />, index: true }],
  },
];
