import React from 'react';
import moment from 'moment';
import { useClick } from '@szhsin/react-menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Card, Stack, Checkbox, useTheme, IconButton, Typography } from '@mui/material';
//
import CollapseItemActions from './collapse-item-actions';
import { CalendarStoreResponseRightItem } from 'src/store/calendar/types';

interface Props {
  item: CalendarStoreResponseRightItem;
}

export default function MapAsideSubWrapperPersonTeamDetailCollapseItem(props: Props) {
  const { item } = props;
  const { palette } = useTheme();
  const ref = React.useRef(null);
  const [isOpen, setOpen] = React.useState(false);
  const anchorProps = useClick(isOpen, setOpen);
  const [checked, setChecked] = React.useState(false);

  return (
    <>
      <Card
        spacing={0.75}
        direction="row"
        component={Stack}
        alignItems="center"
        sx={{
          p: 0.5,
          borderRadius: 0.75,
          border: `1px solid ${isOpen ? palette.primary.lighter : palette.background.default}`,
        }}
      >
        <Checkbox
          disableRipple
          size="small"
          checked={checked}
          onChange={(e) => setChecked(!checked)}
          sx={{ px: 0, opacity: checked ? 1 : 0.25 }}
        />
        <Stack flex={1} direction="row">
          <Stack flex={1}>
            <Typography
              flex={1}
              fontSize={12}
              overflow="hidden"
              variant="subtitle2"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {item.task.name}
            </Typography>
            <Typography variant="caption" color="text.secondary" fontSize={10}>
              {moment(item.targetDate).format('DD.MM.YYYY HH:mm')}
              {moment(item.targetCompleteDate).format(' - HH:mm')}
            </Typography>
          </Stack>
          <Typography variant="caption" color="text.secondary">
            {item.id}
          </Typography>
        </Stack>

        <IconButton size="small" ref={ref} {...anchorProps}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </Card>
      <CollapseItemActions anchorRef={ref} isOpen={isOpen} handleClose={() => setOpen(false)} />
    </>
  );
}
